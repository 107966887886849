/*
 * @Author: qinmengyuan
 * @Date: 2024-07-22 16:15:29
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-09 17:47:31
 * @FilePath: \dataview-viewer-test\src\views\todo\constant.js
 */
export const STATUS_LIST = [
  {
    status: "pending",
    name: "待处理",
    icon: require("./icons/svg/pending.svg"),
    icon_background: "rgba(209, 227, 255, 0.60)",
    background:
      "linear-gradient(94deg, rgba(242, 247, 255, 0.50) 0%, rgba(231, 240, 254, 0.50) 50%, rgba(242, 247, 255, 0.50) 100%), var(--overall-surface-default, #FFF)",
    icon_color: "",
    type: 1,
    task_color: "#E7F0FE",
    point_color: "#4F8EFF",
  },
  {
    status: "initiatedByMe",
    name: "我发起的",
    icon: require("./icons/svg/sponsor.svg"),
    icon_background:
      "linear-gradient(0deg, rgba(160, 235, 187, 0.40) 0%, rgba(160, 235, 187, 0.40) 100%), var(--overall-surface-default, #FFF)",
    background:
      "linear-gradient(94deg, rgba(233, 251, 239, 0.50) 0%, rgba(223, 247, 229, 0.50) 50%, rgba(233, 251, 239, 0.50) 100%), var(--overall-surface-default, #FFF)",
    icon_color: "",
    type: 2,
    task_color: "#DFF7E5",
    point_color: "#4DC077",
  },
  {
    status: "CCToMeTotal",
    name: "抄送我的",
    icon: require("./icons/svg/copy.svg"),
    icon_background:
      "linear-gradient(0deg, rgba(239, 193, 26, 0.28) 0%, rgba(239, 193, 26, 0.28) 100%), var(--overall-surface-default, #FFF)",
    background:
      "linear-gradient(94deg, rgba(254, 246, 223, 0.50) 0%, rgba(254, 246, 223, 0.50) 50%, rgba(254, 246, 223, 0.50) 100%), var(--overall-surface-default, #FFF)",
    icon_color: "",
    unread: "CCToMeUnread",
    type: 3,
    task_color: "#FFECEC",
    point_color: "#FFBE00",
  },
  {
    status: "processedHandled",
    name: "已处理",
    icon: require("./icons/svg/processed.svg"),
    icon_background: "rgba(180, 186, 204, 0.15)",
    background:
      "linear-gradient(94deg, rgba(244, 246, 249, 0.50) 0%, rgba(240, 241, 244, 0.50) 50%, rgba(244, 246, 249, 0.50) 100%), var(--overall-surface-default, #FFF)",
    icon_color: "",
    type: 4,
    task_color: "#F0F1F4",
    point_color: "#BFC7D8",
  },
];
export const STATE_LIST = [
  { value: -1, label: "退回", color: "#FFECEC" },
  { value: 0, label: "发起", color: "#DFF7E5" },
  { value: 1, label: "审核中", color: "#E7F0FE" },
  { value: 2, label: "完成", color: "#F0F1F4" },
  { value: 3, label: "审核中", color: "#E7F0FE" },
  { value: 4, label: "审核中", color: "#E7F0FE" },
  { value: 5, label: "完成", color: "#F0F1F4" },
  { value: 6, label: "拒绝", color: "#FFECEC" },
  { value: 7, label: "作废", color: "#F0F1F4" },
];
