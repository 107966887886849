<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-07-22 09:09:56
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-08-19 08:52:42
 * @FilePath: \dataview-viewer-test\src\views\todo\components\calendar\HoverRow.vue
 * @Description: 
-->
<template >
  <div id="tooltip-cont" v-if="detail" :style="position">
		<div class="top-cont">
			<p>{{ detail?.title }}</p>
			<div  class="task-status">
				<div class="inner-btn" :style="{background: STATE_LIST.find((item) => item.value === +detail?.status)?.color}">
					{{STATE_LIST.find((item) => item.value === +detail?.status)?.label }}
				</div>
			</div>
		</div>
		<ul>
			<li v-for="(item,index) in showList" :key="index">
				<i class="iconfont iconredesign" :class="item.icon"></i>
				<img class="user-avatar" v-if="item.label === 'user_name' && detail.user_avatar" :src="detail?.user_avatar" alt="">
				<p>{{ detail[item.label] || '-' }}</p>  <p class="overdue-status" v-if="item.label === 'endTime'">{{ deadLine(detail) }}</p>
			</li>
		</ul>
  </div>
</template>

<script setup>
/* eslint-disable */
import {defineProps, ref} from 'vue';
import { STATE_LIST } from "@/views/todo/constant.js";
const emits = defineEmits(['change-line-date'])

const props = defineProps({
  detail: {
    type: Object,
    default: null
  },
	position:{
		type: Object,
    default: {
			left: 0,
		}
	}
});
const showList = ref([
	{icon:'icon-redesign-shuzi1',label:'flow_approval_number'},
	{icon:'iconjiagou11',label:'archi_name'},
	{icon:'icon-redesign-yingyong',label:'object_name'},
	{icon:'iconform-shijian',label:'endTime'},
	// {icon:'icon-redesign-xiaoxikuang',label:'archi_name'},
	{icon:'icon-redesign-a-jiedianliucheng',label:'user_name'},
])
// 时间格式化
const deadLine = (item) => {
	const deadLine = item.endDate;
	const nowTime = new Date();
	let date1_s = new Date(deadLine.replace(/-/g, "/"));
	let dateDiff = nowTime.getTime() - date1_s.getTime();
	let date = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
	
	if (nowTime.getTime() > date1_s.getTime() && date) {
		return  "逾期" + date + "天"
  }
	return ''
}


</script>

<style lang="less" scoped>
#tooltip-cont {
  width: 240px;
	position: fixed;
	left: 0;
	z-index: 2001;
  padding: 16px 20px 20px 20px;
	border-radius: var(--Radio-8, 8px);
	background: var(--overall-surface-default, #FFF);
	pointer-events: none;
	/* shadow/on-surface/medium */
	box-shadow: 0px 1px 3px 0px rgba(25, 27, 35, 0.04), 0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset, 0px 2px 16px 0px rgba(25, 27, 35, 0.08);
	.top-cont{
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #F0F1F4;
		padding-bottom: 14px;
		p{
			flex: 1;
			color: var(--text-on-surface-primary, #181B22);
			font-family: "MiSans VF";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.task-status{
			margin-left: 12px;
			.inner-btn{
				font-size: 14px;
				color: #585F6C;
				line-height: 20px;
				border-radius: 6px;
				padding: 4px 12px;
			}
		}
	}
	ul{
		margin-top: 4px;
		li{
			margin-top: 12px;
			color: var(--text-on-surface-primary, #181B22);
			font-family: "MiSans VF";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			display: flex;
			flex-direction: row;
			i{
				margin-right: 16px;
				font-size: 18px;
				color: #8991A2;
				line-height:20px;
			}
			.overdue-status{
				color: #ED474A;
				margin-left: 16px;
				font-size: 14px;
			}
			.user-avatar{
				width: 24px;
				height: 24px;
				border-radius: 50%;
				margin-right: 6px;
			}
		}
	}
}
</style>
