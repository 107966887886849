/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-12-23 14:53:01
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-08-17 14:21:25
 * @FilePath: /dataview-viewer-test/src/views/todo/components/calendar/js/canvasTable.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/*
    fixed          normal
 ------------------------------
 | head_fixed |    head        |
 |------------|----------------|
 |            |                |
 | body_fixed |    task_body   |
 |            |                |
 ------------------------------
 */
import { generateBezier } from "./bezier";
import Scrollbar from "./scrollbar";
import { Rect } from "./common";
import theme from "./theme";
import { parseTime } from "@/utils/util";

const DIR_X = 1;
const DIR_Y = 2;
const TABLE_HEAD_FIXED = 1;
const TABLE_HEAD = 2;
const TABLE_BODY_FIXED = 3;
const TABLE_BODY = 4;
const FORWARD = 5; // 前锋线
const IN_SCROLL_V = 1;
const IN_SCROLL_H = 2;
const IN_BAR_V = 3;
const IN_BAR_H = 4;
const Bezier = generateBezier(0.14, 0.77, 0, 1);
let timer = null;
let todayLinePosition = [10000, 10000]; // 今天线坐标
let dashedLinePosition = [10000, 10000]; // 虚线
let flagPosition = [10000, 10000]; // 时间轴坐标
let treeImgPosition = [10000, 10000]; // 展开按钮
let todayDataNum = ""; // 今天日期
let flagDateNum = ""; // 旗子所在位置日期
let firstDateNum = ""; // header初始时间
let dashedLineDateNum = ""; // 虚线日期
let dragType = "forWardLine";
let isPlaying = false;
let ShiftEnter = false; // Shift按下
let rightEnter = false; // 鼠标右键是否按下
let isDragMode = false; // 拖拽中
let baseItemWidth = 0;
let hoverId = 0;
let translateX = 0; // table和gantt拖拽的偏移量
let baseFixedWidth = 0;
let tableContentWidth = 0; // 右侧滚动数据的总宽度
let maxPosX = 0; // 最大的拖拽距离

function getOrDefault(v, defaultV) {
  return v === undefined ? defaultV : v;
}
function isFunction(f) {
  return typeof f === "function";
}
function isObject(o) {
  return o !== null && typeof o === "object";
}
function makeDelta(cur, last) {
  if (!cur || !last) {
    return { x: 0, y: 0 };
  }
  return {
    x: -(cur.clientX - last.clientX),
    y: -(cur.clientY - last.clientY),
  };
}

function attenuationCoefficient(initV, msFromBegin) {
  const MAX_V = 100;
  if (initV > MAX_V) {
    initV = MAX_V;
  }
  const d = msFromBegin;

  const percent = d / 5000 / (initV / MAX_V);
  if (percent >= 1) {
    return 0;
  }
  const v = Bezier(percent, 1, 0);
  return v;
}
function valueBetween(v, min, max) {
  if (v < min) return min;
  if (v > max) return max;
  return v;
}
function drawTask(ctx, x, y, w, h, r, style, row) {
  let radius = r;
  if (w < 20) {
    radius = 0;
  }
  ctx.save();
  ctx.strokeStyle = "transparent";
  ctx.lineWidth = style.pixelRatio || 1;
  ctx.lineJoin = "round";
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.arcTo(x, y, x + w, y, radius);
  ctx.arcTo(x + w, y, x + w, y + h, radius);
  ctx.arcTo(x + w, y + h, x, y + h, radius);
  ctx.arcTo(x, y + h, x, y, radius);
  ctx.arcTo(x, y, x + w, y, radius);
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
  ctx.fillStyle = row.statusColor;
  ctx.fill();
}

/**
 *
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} style => {color, fontSize, align, fontFamily}
 */
function drawTextInTask(ctx, text, x, y, w, h, style) {
  const { fontSize, color, align } = style;
  let textX = x;
  let textAlign = align;
  const textCol = color;
  let width = w;
  ctx.save();
  ctx.rect(textX, y, width, h);
  ctx.clip();
  ctx.font = `${fontSize}px 微软雅黑`;
  ctx.fillStyle = textCol;
  const yOffset = y + h - (h - fontSize) / 2;
  const textWidth = ctx.measureText(text).width;
  let xOffset = textX;
  if (textAlign === "left") {
    xOffset = textX;
  } else if (textAlign === "right") {
    xOffset = textX + (width - textWidth);
  } else {
    xOffset = textX + (width - textWidth) / 2;
  }
  if (text?.length) {
    const text1 = text.replaceAll(".", "");
    if (ctx.measureText(text1).width >= w) {
      for (let i = text1.length - 1; i >= 0; i--) {
        if (ctx.measureText(`${text1.substring(0, i)}...`).width <= w) {
          text = text1.substring(0, i-1) + "...";
          break;
        }
      }
    }
  }
  if(text[0] !== '【'){
    xOffset = xOffset + 5
  }
  ctx.fillText(text, xOffset, yOffset);
  ctx.restore();
}

/**
 *
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} style => {color, fontSize, align, fontFamily}
 */
function drawTextInTable(ctx, text, x, y, w, h, style, row, col) {
  const { fontSize, color, align, borderColor, borderWidth, backgroundColor } = style;
  ctx.save();
  ctx.rect(x, y, w, h);
  ctx.clip();
  if (backgroundColor) {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(x, y, w, h);
  }
  ctx.font = `${fontSize}px 微软雅黑`;
  ctx.fillStyle = color || theme.COLOR;
  let xOffset = x;
  const yOffset = y + h - (h - fontSize) / 2;
  const textWidth = ctx.measureText(text).width;
  if (align === "left") {
    xOffset = x;
    // 树形结构空格递增显示层次
    if (col?.treeIcon && row?.wbs) {
      const wbsArr = row.wbs.split(".");
      if (row?.children?.length) {
        xOffset += wbsArr.length * 20;
      } else {
        xOffset += (wbsArr.length - 1) * 20 + 5;
      }
    }
  } else if (align === "right") {
    xOffset = x + (w - textWidth);
  } else {
    // default: center
    xOffset = x + (w - textWidth) / 2;
  }
  if (text?.length) {
    const text1 = text.replaceAll(".", "");
    if (ctx.measureText(text1).width >= w) {
      for (let i = text1.length - 1; i >= 0; i--) {
        if (ctx.measureText(`${text1.substring(0, i)}...`).width < w) {
          text = text1.substring(0, i) + "...";
          break;
        }
      }
    }
  }
  ctx.fillText(text, xOffset, yOffset - 2);
  ctx.strokeStyle = borderColor;
  ctx.lineCap = "round";
  ctx.lineJoin = "miter";
  ctx.beginPath();
  ctx.lineWidth = borderWidth;
  ctx.moveTo(x + w, y);
  ctx.lineTo(x + w, y + h);
  ctx.lineTo(x, y + h);
  ctx.stroke();
  ctx.restore();
}

/**
 * @description: 绘制header
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} style
 * @param {*} isEndItem 最后一个
 */
function drawTextInHeader(
  ctx,
  text,
  x,
  y,
  w,
  h,
  style,
  col
) {
  const { fontSize, color, align, borderColor, dateType } = style;
  ctx.save();
  ctx.rect(x, y, w, h);
  ctx.clip();
  if (style.backgroundColor) {
    ctx.fillStyle = style.backgroundColor;
    ctx.fillRect(x, y, w, h);
  }
  ctx.font = `${fontSize}px 微软雅黑`;
  ctx.textAlign = "left"; // 水平对齐设置
  ctx.textBaseline = "middle"; // 垂直居中
  let xOffset = x;
  const yOffset = y + h / 2;
  let textWidth = ctx.measureText(text).width;
  if (align === "left") {
    xOffset = x;
  } else if (align === "right") {
    xOffset = x + (w - textWidth);
  } else {
    xOffset = x + (w - textWidth) / 2;
  }
  //右侧还要显示周几
  if(dateType === "week"){
    xOffset = xOffset - 20;
  }
  //绘制日期红色圆背景
  if(col.field === 'itemDate' && col.isToday){
    ctx.beginPath();
    ctx.arc(textWidth < 10 ? xOffset + 4: xOffset + 8, y + 17, 12, 0, 2 * Math.PI);
    ctx.fillStyle = '#ED474A'; // 设置填充颜色
    ctx.fill(); // 填充圆
  }
  ctx.fillStyle = color || theme.COLOR;
  if(col.field === 'itemDate' && col.isToday){
    ctx.fillStyle = '#ffffff'; 
  }
  ctx.fillText(text, xOffset, yOffset);
  //右侧渲染周几
  if(dateType === "week"){
    ctx.fillStyle = color || theme.COLOR;
    ctx.fillText(col.dayOfWeek,textWidth < 10?xOffset + textWidth + 14 : xOffset + textWidth + 10, yOffset);
  }
  ctx.strokeStyle = borderColor;
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.moveTo(x + w, y);
  ctx.lineTo(x + w, y + h);
  ctx.lineTo(x, y + h);
  ctx.stroke();
  ctx.restore();
}

/**
 * @description: 绘制任务条纹背景
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} backgroundColor
 */
function drawBgInTaskBg(ctx, x, y, w, h, backgroundColor, borderColor) {
  ctx.save();
  ctx.rect(x, y, w, h);
  ctx.clip();
  if (backgroundColor) {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(x, y, w, h);
  }
  ctx.strokeStyle = borderColor;
  ctx.lineWidth = 0.5;
  ctx.beginPath();
  ctx.moveTo(x + w, y);
  ctx.lineTo(x + w, y + h);
  ctx.stroke();
  ctx.restore();
}

/**
 * @description: 获取点击或移入的row，col
 * @param {*} x
 * @param {*} y
 * @param {*} cols
 * @param {*} rows
 * @param {*} option
 * @return {*}
 */
function getCell(x, y, cols, rows, option) {
  let rowIndex = 0;
  let colIndex = null;
  let width = 0;
  let row = null;
  let col = null;
  rowIndex = Math.floor(y / option.rowHeight);
  if (rows && rows.length > 0 && rows[rowIndex] !== undefined) {
    row = rows[rowIndex];
  }
  for (let i = 0; i < cols.length; i++) {
    width += cols[i].widthItem;
    if (width >= x) {
      // 处理操作点击
      if (
        (cols[i].field === "handle" || cols[i].field === "location") &&
        cols[i].children?.length &&
        row !== null
      ) {
        width -= cols[i].widthItem;
        for (var j = 0; j < cols[i].children.length; j++) {
          width += cols[i].children[j].widthItem;
          if (width - x <= cols[i].children[j].widthItem && width - x > 0) {
            col = cols[i].children[j];
          }
        }
      } else {
        colIndex = i;
      }
      break;
    }
  }

  if (colIndex !== null) {
    col = cols[colIndex];
  }
  return { row, col };
}
/**
 * @description: 获取相差天数
 * @param {*} startDate
 * @param {*} enDate
 * @return {*}
 */
function getDaysBetween(startDate, enDate) {
  const sDate = Date.parse(startDate);
  const eDate = Date.parse(enDate);
  if (sDate > eDate) {
    return -1;
  }
  // 这个判断可以根据需求来确定是否需要加上
  if (sDate === eDate) {
    return 0;
  }
  const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}
/**
 * @description: 前后推几天
 * @param {*} date
 * @param {*} num
 * @return {*}
 */
function getDiffDate(date, num) {
  const milliseconds =
    new Date(date).getTime() - parseInt(num) * 1000 * 60 * 60 * 24;
  const myDate = new Date(milliseconds);
  const Y = myDate.getFullYear();
  let M = myDate.getMonth() + 1;
  let D = myDate.getDate();
  if (M < 10) {
    M = "0" + M;
  }
  if (D < 10) {
    D = "0" + D;
  }
  return Y + "-" + M + "-" + D;
}
export class CanvasTable {
  constructor(rootElm, id) {
    if (!rootElm) return;
    // 不做太多初始化，仅创建canvas元素
    this.rootElm = rootElm;
    this.canvas = document.createElement("canvas");
    this.canvas.id = `canvasBox_${id}`;
    this.ctx = this.canvas.getContext("2d");
    rootElm.appendChild(this.canvas);
    this.bindEvent();
    this.posX = 0;
    this.posY = 0; // 当前显示位置坐标 (CSS坐标)
    this.fixedWidth = 0; // 左侧固定总列宽
    this.touchDir = null;
    this.startPoint = null;
    this.lastPoint = null;
    this.requestFlag = false;
    this.touchMoveVelocity = 0;
    this.hasScrollbarV = false;
    this.hasScrollbarH = false;
    this.scrollbarH = null;
    this.scrollbarV = null;
    this.waitingRender = false;
  }

  get top() {
    return this.posY * this.pixelRatio;
  }

  set top(value) {
    const y = value / this.pixelRatio;
    this.posY = valueBetween(y, 0, this.C_MAX_POS_Y);
    this.requestRefresh();
  }

  get left() {
    return this.posX * this.pixelRatio;
  }

  set left(value) {
    const x = value / this.pixelRatio;
    this.posX = valueBetween(x, 0, this.C_MAX_POS_X);
    this.requestRefresh();
    if (this.option.isPlayAble) {
      // 拖动滚动条将旗子所在的日期暴露出去
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (flagPosition[0] !== 10000) {
          const lineNum = Math.round(
            (flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth
          );
          flagDateNum = getDiffDate(firstDateNum, -lineNum);
          if (isFunction(this.getFlagDate)) {
            this.getFlagDate(flagDateNum, false, isPlaying);
          }
        }
      }, 200);
    }
  }


  // 这里有问题，需要改进，分两种转换，一种是 设计尺寸到canvas像素， 一种是css像素到canvas像素
  designToCanvasPx(x) {
    if (x === undefined || x === null) return x;
    return x * this.designScale;
  }

  canvasToCssPx(x) {
    if (x === undefined || x === null) return x;
    return x / this.pixelRatio;
  }

  cssToCanvasPx(x) {
    if (x === undefined || x === null) return x;
    return x * this.pixelRatio;
  }

  initOption(option) {
    const { designWidth, top, left, maxHeight } = option;
    const { ctx } = this;
    const boundingRect = this.rootElm?.getBoundingClientRect();
    if (!boundingRect) {
      return;
    }
    // pixelRatio: 像素比例，canvas像素比css像素的值，就是1个css像素对应几个canvas像素
    // designScale: canvas宽度与设计稿宽度的比例，不指定设计稿宽度则为1，此值用来计算实际绘制尺寸，比如线宽、文字大小等。 drawValue = valueSpecified * designScale;
    // this.pixelRatio = window.devicePixelRatio
    this.pixelRatio = 1;
    this.cssWidth = boundingRect.width;
    // 将baseItemWidth有补齐宽度的情况
    baseItemWidth = option.dayWidth;
    this.canvasWidth = boundingRect.width * this.pixelRatio;
    this.designWidth = designWidth || boundingRect.width * this.pixelRatio;
    if (this.canvasWidth < this.designWidth) {
      this.canvasWidth = this.designWidth;
      this.pixelRatio = this.canvasWidth / boundingRect.width;
    }
    this.designScale = this.canvasWidth / this.designWidth; // 在不指定designWidth的情况下，为1，值域大于等于1
    this.fillDefaultOption(option);
    let cssHeight = Math.max(boundingRect.height, option.maxHeight);
    const tableCssHeight = Math.ceil(
      (option.list.length * option.rowHeight +
        option.headHeight +
        option.scrollbarWidth) /
        this.pixelRatio
    );
    cssHeight = Math.max(Math.min(cssHeight, tableCssHeight), 100);
    this.canvasHeight = Math.max(cssHeight * this.pixelRatio, maxHeight);
    this.cssHeight = cssHeight;
    this.canvas.style.display = "block";
    // this.canvas.style.borderTop = `1px solid ${this.option.borderColor}`
    this.canvas.style.border = `1px solid ${this.option.borderColor}`;
    // viewWidth, viewHeight 主canvas可视区的宽高，排除滚动条的,减去上右边框
    this.canvas.width = this.canvasWidth - 2;
    this.canvas.height = this.canvasHeight - 2;
    // reduce遍历相加
    tableContentWidth = option.normalCols.reduce((acc, i) => {
      return acc + getOrDefault(i.widthItem, option.colWidth);
    }, 0);
    /**
     * @description: 清空canvas
     * @return {*}
     */
    this.clearCanvas = () => {
      clearInterval(timer);
      isPlaying = false;
      const ctx = this.ctx;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.save();
    };
    
    /**
     * @description: 暴露出刷新供实例调用
     * @return {*}
     */
    this.requestRefresh = () => {
      if (!this.requestFlag) {
        this.requestFlag = true;
        window.requestAnimationFrame(this.draw.bind(this));
      }
    };
    /**
     * @description: 暴露出播放方法供实例调用
     * @return {*}
     */
    this.playGantt = (type) => {
      if (type) {
        isPlaying = true;
        let isEnd = false;
        timer = setInterval(() => {
          if (this.option.dateType === "day") {
            this.posX += 0.8;
          } else if (this.option.dateType === "month") {
            this.posX += 0.2;
          } else {
            this.posX += 0.5;
          }
          if (
            this.posX >=
            tableContentWidth - (flagPosition[0] - this.fixedWidth)
          ) {
            isPlaying = false;
            clearInterval(timer);
            this.posX = 0;
            isEnd = true;
          }
          this.requestRefresh();
          if (this.posX % baseItemWidth === 0) {
            const lineNum = Math.round(
              (flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth
            );
            flagDateNum = getDiffDate(firstDateNum, -lineNum);
            if (isFunction(this.getFlagDate)) {
              this.getFlagDate(flagDateNum, isEnd, isPlaying);
            }
          }
        }, 25);
      } else {
        isPlaying = false;
        clearInterval(timer);
      }
    };
    // headHeight 和 fixedWidth 不受滚动条影响。
    // 受滚动条影响的是 viewWidth 和 viewHeight
    const { headHeight } = option;
    const tableContentHeight = option.list.length * option.rowHeight;
    const tableHeight = headHeight + tableContentHeight;
    const fixedWidth = option.fixedCols.reduce((acc, i) => {
      // 仅表格时fixedWidth为可视宽度
      if (this.option.onlyTable && !this.option.tableFixed) {
        if (tableHeight > this.canvasHeight) {
          return this.canvasWidth - this.option.scrollbarWidth;
        } else {
          return this.canvasWidth;
        }
      } else if (this.option.onlyGantt) {
        return 0;
      } else {
        return acc + getOrDefault(i.widthItem, option.colWidth);
      }
    }, 0);

    let tableWidth = fixedWidth + tableContentWidth;
    // // 补齐后面宽度
    // if (tableWidth <= this.canvasWidth) {
    //   tableWidth = this.canvasWidth;
    // }
    // 仅表格
    if (this.option.onlyTable && !this.option.tableFixed) {
      tableWidth = fixedWidth;
    }
    // 仅gantt
    if (this.option.onlyGantt) {
      tableWidth = tableContentWidth;
    }

    // 默认没有滚动条，有type
    this.viewWidth = Number(this.canvasWidth);
    this.viewHeight = this.canvasHeight;
    this.hasScrollbarV = false;
    this.hasScrollbarH = false;
    // 计算scrollbar， 修正viewWidth 和 viewHeight
    if (!this.hasScrollbarV && tableHeight > this.viewHeight) {
      // 内容高度超出可显示区域，需要垂直滚动条
      this.hasScrollbarV = true;
      this.viewWidth = this.canvasWidth - option.scrollbarWidth;
    }
    // 水平滚动条显示，需要注意是否有垂直滚动条，需要判断
    if (
      !this.hasScrollbarH && this.hasScrollbarV
        ? Number(tableWidth).toFixed(2) > this.viewWidth + option.scrollbarWidth
        : Number(tableWidth).toFixed(2) > this.viewWidth
    ) {
      // 内容宽度超出可显示区域，需要水平滚动条
      this.hasScrollbarH = true;
      this.viewHeight = this.canvasHeight - option.scrollbarWidth;
    }
    // 增加水平滚动条会导致viewHeight变小，可能出现垂直滚动条
    if (!this.hasScrollbarV && tableHeight > this.viewHeight) {
      // 内容高度超出可显示区域，需要垂直滚动条
      this.hasScrollbarV = true;
      this.viewHeight = this.canvasHeight - option.scrollbarWidth;
    }

    this.tableWidth = tableWidth;
    this.tableHeight = tableHeight;
    if (translateX && !this.option.onlyTable) {
      if (this.option.onlyGantt) {
        this.fixedWidth = 0;
      } else {
        this.fixedWidth = translateX;
        if (isFunction(this.getFixWidth)) {
          this.getFixWidth(translateX);
        }
      }
    } else {
      this.fixedWidth = fixedWidth;
      if (isFunction(this.getFixWidth)) {
        this.getFixWidth(fixedWidth);
      }
    }
    baseFixedWidth = fixedWidth;

    this.headHeight = headHeight;
    if (this.hasScrollbarV) {
      this.scrollbarV = new Scrollbar({
        ctx,
        type: Scrollbar.VER,
        scrollHeight: tableContentHeight,
        clientHeight: this.viewHeight - this.headHeight,
        rect: new Rect(
          this.viewWidth,
          this.headHeight,
          option.scrollbarWidth,
          this.viewHeight - this.headHeight
        ),
        style: {
          foregroundColor: option.scrollbarForegroundColor,
          backgroundColor: option.scrollbarBackgroundColor,
          borderColor: option.borderColor,
        },
      });
    }
    if (this.hasScrollbarH) {
      this.scrollbarH = new Scrollbar({
        ctx,
        type: Scrollbar.HOR,
        scrollWidth: tableContentWidth,
        clientWidth: this.viewWidth - this.fixedWidth,
        rect: new Rect(
          this.fixedWidth,
          this.viewHeight,
          this.viewWidth - this.fixedWidth,
          option.scrollbarWidth
        ),
        style: {
          foregroundColor: option.scrollbarForegroundColor,
          backgroundColor: option.scrollbarBackgroundColor,
          borderColor: option.borderColor,
        },
      });
    }
    if (this.option.onlyGantt) {
      this.C_MAX_POS_X = this.canvasToCssPx(
        Math.max(tableWidth - this.viewWidth, 0)
      );
    } else {
      this.C_MAX_POS_X =
        maxPosX || this.canvasToCssPx(Math.max(tableWidth - this.viewWidth, 0));
    }
    this.C_MAX_POS_Y = this.canvasToCssPx(
      Math.max(tableHeight - this.viewHeight, 0)
    );

    this.waitingRender = false;
    this.top = top || 0;
    this.left = left || 0;
    // 回显滚动条
    if (this.option.defaultScrollerPosition) {
      let bol = false;
      // if (this.option.defaultScrollerPosition.x && this.hasScrollbarH) {
      //   bol = true
      //   this.posX = this.option.defaultScrollerPosition.x
      //   this.option.defaultScrollerPosition.x = 0
      // }
      if (this.option.defaultScrollerPosition.y && this.hasScrollbarV) {
        bol = true;
        this.posY = this.option.defaultScrollerPosition.y;
        this.option.defaultScrollerPosition.y = 0;
      }
      if (bol) {
        this.requestRefresh();
      }
    }
  }

  fillDefaultOption(option) {
    const initValue = (v, dv) => {
      if (v === undefined || v === null) {
        return this.designToCanvasPx(dv);
      } else {
        return this.designToCanvasPx(v);
      }
    };
    option.color = getOrDefault(option.color, theme.COLOR);
    option.backgroundColor = getOrDefault(
      option.backgroundColor,
      theme.BACKGROUND_COLOR
    );
    option.hoverBackgroundColor = getOrDefault(
      option.hoverBackgroundColor,
      theme.HOVER_BACKGROUND_COLOR
    );
    option.borderColor = getOrDefault(option.borderColor, theme.BORDER_COLOR);
    option.borderWidth = initValue(option.borderWidth, 1);
    option.headColor = getOrDefault(option.headColor, option.color);
    option.headBackgroundColor = getOrDefault(
      option.headBackgroundColor,
      option.backgroundColor
    );
    option.rowHeight = initValue(option.rowHeight, 100);
    option.headHeight = getOrDefault(
      this.designToCanvasPx(option.headHeight),
      option.rowHeight
    );
    option.colWidth = initValue(option.colWidth, 400);
    option.fontSize = initValue(option.fontSize, 28);
    option.scrollbarWidth = initValue(option.scrollbarWidth, 16);
    option.maxHeight = getOrDefault(option.maxHeight, 0); // css value
    option.fixedCols = [];
    option.normalCols = [];
    if (option.cols) {
      let totalDateWidth = 0;
      let totalDays = 0;
      let fixedWidth = 0;
      option.cols.forEach((col) => {
        col.align = getOrDefault(col.align, "center");
        col.titleAlign = getOrDefault(col.titleAlign, col.align);
        col.sort = getOrDefault(col.sort, false);
        col.fixed = getOrDefault(col.fixed, false);
        // this.option.itemWidth宽度
        if (!col.width && col.field === "itemDate") {
          // 定义顶部的宽度
          if (col.children?.length) {
            let n = 0;
            for (let i = 0; i < col.children.length; i++) {
              if (col.children[i].itemDays) {
                n += col.children[i].itemDays;
                totalDays += col.children[i].itemDays;
              }
            }
            col.widthItem = n * this.option.dayWidth;
            col.itemDays = n;
          }
          totalDateWidth += col.widthItem;
        } else {
          col.widthItem = this.designToCanvasPx(col.width) || option.colWidth; // 每列的canvas像素宽度
        }
        if (col.fixed) {
          option.fixedCols.push(col);
          fixedWidth += Number(col.width);
        } else {
          if (option.tableFixed) {
            if (col.field !== "itemDate") {
              option.normalCols.push(col);
            }
          } else {
            option.normalCols.push(col);
          }
        }
      });
      if (
        (this.option.onlyGantt && this.canvasWidth > totalDateWidth) ||
        this.canvasWidth > totalDateWidth + fixedWidth
      ) {
        // 补齐剩余宽度
        if (this.option.onlyGantt) {
          baseItemWidth = this.canvasWidth / totalDays;
        } else {
          baseItemWidth = (this.canvasWidth - fixedWidth) / totalDays;
        }
      }
    }
  }

  bindEvent() {
    this.canvas.addEventListener("wheel", (e) => {
      this.stopInertiaScroll();
      const deltaY = Math.round(e.deltaY);
      if (ShiftEnter) {
        this.addPosX(deltaY);
      } else {
        this.addPosY(deltaY);
      }
    });
    this.touchHandler = this.touchHandler.bind(this);
    this.canvas.addEventListener("touchstart", this.touchHandler);
    this.canvas.addEventListener("touchmove", this.touchHandler);
    this.canvas.addEventListener("touchend", this.touchHandler);
    this.canvas.addEventListener("touchcancel", this.touchHandler);
    this.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    this.mouseHandler = this.mouseHandler.bind(this);
    this.canvas.addEventListener("mousedown", this.mouseHandler);
    window.addEventListener("mousemove", this.mouseHandler);
    window.addEventListener("mouseup", this.mouseHandler);
    this.canvas.addEventListener("click", this.clickHandler.bind(this));
  }

  removeEventHandler() {
    window.removeEventListener("mousemove", this.mouseHandler);
    window.removeEventListener("mouseup", this.mouseHandler);
  }

  clickHandler(e) {
    const x = e.clientX;
    const y = e.clientY;
    if (
      this.mousedownPoint &&
      this.mousedownPoint.clientX === x &&
      this.mousedownPoint.clientY === y
    ) {
      const rect = this.canvas.getBoundingClientRect();
      const cx = Math.floor(x - rect.left);
      const cy = Math.floor(y - rect.top);
      const res = this.testInScrollbar(cx, cy);
      if (res > 0) return; // 滚动条区域，忽略
      const vx = this.cssToCanvasPx(cx);
      const vy = this.cssToCanvasPx(cy);
      let rx = vx + this.left;
      let ry = vy + this.top;

      const { option } = this;
      const { normalCols, fixedCols, list } = option;
      let cell = null;
      let area = null;
      // 四个区域
      if (vx >= this.fixedWidth && vy >= this.headHeight) {
        rx -= this.fixedWidth;
        ry -= this.headHeight;
        area = TABLE_BODY;
        cell = getCell(rx, ry, normalCols, list, option);
      } else if (vx >= this.fixedWidth) {
        rx -= this.fixedWidth;
        area = TABLE_HEAD;
        cell = getCell(rx, ry, normalCols, [], option);
      } else if (vy >= this.headHeight) {
        ry -= this.headHeight;
        area = TABLE_BODY_FIXED;
        cell = getCell(rx - this.posX, ry, fixedCols, list, option);
      } else {
        area = TABLE_HEAD;
        cell = getCell(rx - this.posX, ry, fixedCols, [], option);
      }
      if (cell.col) {
        // 点击事件
        this.clickCell({ ...cell, area });
      }
      if (area === TABLE_HEAD && cell.col) {
        if (
          cell.col.field === "name" &&
          treeImgPosition[0] <= cx &&
          cx <= treeImgPosition[0] + 18 &&
          !this.option.editMode
        ) {
          if (isFunction(this.showTreeLevel)) {
            this.showTreeLevel();
            return;
          }
        }
        const col = cell.col;
        if (col.sort) {
          if (isFunction(this.onsort)) {
            this.onsort(cell, {
              field: col.field,
              type:'',
            });
          }
        }
      }
    }
  }
  /**
   * @description: 改变滚动条高度
   * @return {*}
   */
  changeScrollerHeight() {
    this.init({
      ...this.option,
      top: this.top,
      left: this.left,
    });
    if (this.hasScrollbarV) {
      const tableContentHeight =
        this.option.list.length * this.option.rowHeight;
      // 修改滚动最大值
      this.C_MAX_POS_Y = this.canvasToCssPx(
        Math.max(
          this.option.headHeight + tableContentHeight - this.viewHeight,
          0
        )
      );
      const { ctx } = this;
      this.scrollbarV = new Scrollbar({
        ctx,
        type: Scrollbar.VER,
        scrollHeight: tableContentHeight,
        clientHeight: this.viewHeight - this.headHeight,
        rect: new Rect(
          this.viewWidth,
          this.headHeight,
          this.option.scrollbarWidth,
          this.viewHeight - this.headHeight
        ),
        style: {
          foregroundColor: this.option.scrollbarForegroundColor,
          backgroundColor: this.option.scrollbarBackgroundColor,
        },
      });
    }
  }

  /**
   * @description: 点击表格
   * @param {*} cell
   * @return {*}
   */
  clickCell(cell) {
    if (isFunction(this.onclick)) {
      this.onclick(cell);
    }
  }
  /**
   * @description: 移入显示任务详情
   * @param {*} cell
   * @return {*}
   */
  showTitips(item, x, y, clientY){
    if (isFunction(this.hoverTask)) {
      this.hoverTask(item,x,y, clientY);
    }
  }

  async mouseHandler(e) {
    const dragLine = document.getElementById("dragLine");
    const rect = this.canvas.getBoundingClientRect();
    const { option, viewWidth, viewHeight, headHeight, fixedWidth, ctx } = this;
    const cx = Math.floor(e.clientX - rect.left);
    const cy = Math.floor(e.clientY - rect.top);
    let res;
    switch (e.type) {
      case "mousedown":
        this.mousedownPoint = e;
        this.stopInertiaScroll();
        res = this.testInScrollbar(cx, cy);
        if (e.button === 2) {
          rightEnter = true;
        }
        if (res > 0 || e.button === 2) {
          // 右键长按拖动滚动左右
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor =
            "pointer";
          this.mouseScrolling = true;
          if (res === IN_SCROLL_V) {
            this.setScrollbarPosition(cy, DIR_Y);
          } else if (res === IN_SCROLL_H) {
            this.setScrollbarPosition(cx, DIR_X);
          }
          this.firstDraggingPoint = e;
          this.lastDraggingPoint = e;
          this.lastDraggingArea = res;
          this.draggingStartTop = this.top;
          this.draggingStartLeft = this.left;
        } else {
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor =
            "default";
        }
        // 触发拖拽时间轴旗子
        if (
          flagPosition[0] + 25 >= cx &&
          flagPosition[0] <= cx &&
          flagPosition[1] + this.headHeight >= cy &&
          flagPosition[1] <= cy
        ) {
          dragType = "flag";
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor =
            "col-resize";
          this.playGantt(false);
          isDragMode = true;
        }
        // 触发table和gantt中线
        if (
          !this.option.onlyGantt &&
          !this.option.onlyTable &&
          this.fixedWidth + 5 >= cx &&
          this.fixedWidth - 5 <= cx
        ) {
          dragType = "tableGanttLine";
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = "col-resize";
          isDragMode = true;
          this.playGantt(false);
        }
        break;
      case "mousemove":
        if (this.mouseScrolling) {
          const d = makeDelta(e, this.firstDraggingPoint);
          if (
            this.lastDraggingArea === IN_SCROLL_V ||
            this.lastDraggingArea === IN_BAR_V
          ) {
            const dy = d.y;
            const scale = this.scrollbarV.verticalPixelRatio;
            this.top = this.draggingStartTop - scale * dy * this.pixelRatio;
          } else {
            const dx = d.x;
            const scale = this.scrollbarH?.horizontalPixelRatio || 0;
            if (!scale) {
              return;
            }
            if (rightEnter) {
              this.left =
                this.draggingStartLeft + scale * (dx * this.pixelRatio);
            } else {
              this.left =
                this.draggingStartLeft - scale * (dx * this.pixelRatio);
            }
          }
          this.lastDraggingPoint = e;
        }
        if (isDragMode && dragLine) {
          dragLine.style.display = "block";
          dragLine.style.height = viewHeight + "px";
          dragLine.style.left = cx + 17 + "px";
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor =  "col-resize";
        }
        // hover效果
        var { normalCols, fixedCols, list, tableFixed } = option;
        var vy = this.cssToCanvasPx(cy) + this.posY;
        var toolTipCont = document.getElementById("tooltip-cont");
        if (
          cx > 0 &&
          cx < viewWidth &&
          vy > this.option.headHeight + this.posY &&
          vy < viewHeight + this.posY &&
          !isPlaying &&
          e.target.nodeName === "CANVAS"
        ) {
          const cell = getCell(
            cx,
            vy - this.option.rowHeight,
            normalCols,
            list,
            option
          );
          if (cell?.row && hoverId !== cell.row.id) {
            ctx.clearRect(0, 0, viewWidth, viewHeight);
            if (tableFixed) {
              const rectFixedTable = {
                x: fixedWidth,
                y: headHeight,
                w: viewWidth - fixedWidth,
                h: viewHeight - headHeight,
              };
              this.renderTableBody(rectFixedTable, normalCols, TABLE_BODY);
              this.renderHead(
                {
                  x: fixedWidth,
                  y: 0,
                  w: this.canvasWidth - fixedWidth,
                  h: headHeight,
                },
                normalCols,
                TABLE_HEAD
              );
            } else {
              // 绘制任务条条纹背景
              this.renderTaskBg(
                {
                  x: fixedWidth,
                  y: headHeight,
                  w: viewWidth - fixedWidth,
                  h: viewHeight - headHeight,
                },
                list,
                normalCols,
                TABLE_BODY
              );
              // 绘制任务条
              this.renderTaskBody(
                {
                  x: fixedWidth,
                  y: headHeight,
                  w: viewWidth - fixedWidth,
                  h: viewHeight - headHeight,
                },
                TABLE_BODY
              );
            }
            // 渲染今天
            if (!this.option.onlyTable && this.option.dateType === 'day' && this.option.list?.length) {
              this.rendTimeLine(
                {
                  x: fixedWidth,
                  y: headHeight,
                  w: viewWidth - fixedWidth,
                  h: viewHeight - headHeight,
                },
                FORWARD,
              );
            }
            // 渲染拖拽的虚线
            if (dashedLineDateNum) {
              this.rendDashedLine(
                {
                  x: fixedWidth,
                  y: headHeight,
                  w: viewWidth - fixedWidth,
                  h: viewHeight - headHeight,
                },
                normalCols,
                FORWARD,
                dashedLineDateNum
              );
            }
            if (!this.option.onlyTable) {
              if (this.option.onlyGantt) {
                this.renderHead(
                  { x: 0, y: 0, w: viewWidth, h: headHeight },
                  normalCols,
                  TABLE_HEAD
                );
              } else {
                this.renderHead(
                  {
                    x: fixedWidth,
                    y: 0,
                    w: this.canvasWidth - fixedWidth,
                    h: headHeight,
                  },
                  normalCols,
                  TABLE_HEAD
                );
              }
            }
            // 渲染header和table
            if (!this.option.onlyGantt) {
              this.renderTableBody(
                {
                  x: 0,
                  y: headHeight,
                  w: fixedWidth,
                  h: viewHeight - headHeight,
                },
                fixedCols,
                TABLE_BODY_FIXED
              );
              this.renderHead(
                { x: 0, y: 0, w: fixedWidth, h: headHeight },
                fixedCols,
                TABLE_HEAD_FIXED
              );
            }
            // 任务移入hover效果,位置放到后面
            // this.renderTaskHoverBg(
            //   {
            //     x: 0,
            //     y: headHeight,
            //     w: this.canvasWidth,
            //     h: viewHeight - headHeight,
            //   },
            //   cell.row.id
            // );
            this.renderScrollbar();
            hoverId = cell.row.id;
          }
          // 移入显示待办详情
          if (this.option.tooltip && cell?.row ) {
            this.showTitips(cell.row,cx,cy, e.clientY);
          }
        } else {
          if (toolTipCont) {
            this.showTitips(null);
          }
        }
        break;
      case "mouseup":
        rightEnter = false;
        if (dragLine) {
          dragLine.style.display = "none";
        }
        if (
          document.getElementById(`canvasBox_${this.option.id}`)?.style.cursor === "pointer"
        ) {
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = "default";
        }
        // 时间分割虚线
        if (
          document.getElementById(`canvasBox_${this.option.id}`)?.style
            .cursor === "col-resize" &&
          dragType === "halvingLine"
        ) {
          let moveNum = 0;
          if (dashedLineDateNum) {
            moveNum = Math.round((dashedLinePosition[0] - cx) / baseItemWidth);
            dashedLineDateNum = await getDiffDate(dashedLineDateNum, moveNum);
          } else {
            moveNum = Math.round((todayLinePosition[0] - cx) / baseItemWidth);
            dashedLineDateNum = await getDiffDate(todayDataNum, moveNum);
          }
          if (dashedLineDateNum === todayDataNum) {
            dashedLineDateNum = "";
            dashedLinePosition = [10000, 10000];
          }
          this.requestRefresh();
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor =
            "default";
          isDragMode = false;
        }
        // table和gantt拖拽
        if (
          document.getElementById(`canvasBox_${this.option.id}`)?.style
            ?.cursor === "col-resize" &&
          dragType === "tableGanttLine"
        ) {
          translateX = cx;
          if (this.hasScrollbarH) {
            this.C_MAX_POS_X = this.C_MAX_POS_X - (this.fixedWidth - cx);
            maxPosX = this.C_MAX_POS_X;
            this.scrollbarH = new Scrollbar({
              ctx,
              type: Scrollbar.HOR,
              scrollWidth: tableContentWidth,
              clientWidth: this.viewWidth - cx,
              rect: new Rect(
                cx,
                this.viewHeight,
                this.viewWidth - cx,
                this.option.scrollbarWidth
              ),
              style: {
                foregroundColor: this.option.scrollbarForegroundColor,
                backgroundColor: this.option.scrollbarBackgroundColor,
                borderColor: this.option.borderColor,
              },
            });
          }
          this.fixedWidth = cx;
          if (this.option.isPlayAble) {
            // 从新计算日期
            let lineNum = 0;
            if (baseFixedWidth < this.fixedWidth) {
              lineNum = Math.round(
                (cx + this.posX - this.fixedWidth) / baseItemWidth
              );
            } else {
              lineNum = Math.round(
                (flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth
              );
            }
            flagDateNum = getDiffDate(firstDateNum, -lineNum);
            if (isFunction(this.getFlagDate)) {
              this.getFlagDate(flagDateNum, false, isPlaying);
            }
          }
          // 将值传递给伸展和收起图标
          if (isFunction(this.getFixWidth)) {
            this.getFixWidth(cx);
          }
          this.requestRefresh();
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor =
            "default";
        }
        this.mouseScrolling = false;
        isDragMode = false;
        break;
    }
  }

  touchHandler(e) {
    // clientXY - 当前视口
    // pageXY - 当前页面
    // screen - 显示屏幕坐标
    let point, delta;
    let preventDefault = true;
    let rect, cx, cy, res;
    switch (e.type) {
      case "touchstart":
        this.stopInertiaScroll();
        point = e.touches[0];
        // 在滚动条区域，不处理
        rect = this.canvas.getBoundingClientRect();
        cx = Math.floor(point.clientX - rect.left);
        cy = Math.floor(point.clientY - rect.top);
        res = this.testInScrollbar(cx, cy);
        if (res > 0) {
          return;
        }
        this.lastTouchEventTimpStamp = Date.now();
        this.touchDir = null;
        this.startPoint = point;
        this.lastPoint = point;
        this.touchMoveVelocity = 0;
        this.touching = true;
        break;
      case "touchmove":
        if (!this.touching) return;
        point = e.touches[0];
        delta = makeDelta(point, this.lastPoint);
        if (!this.touchDir) {
          const dx = Math.abs(delta.x);
          const dy = Math.abs(delta.y);
          if (dx > dy && dx > 4) {
            this.touchDir = DIR_X;
          } else if (dy > dx && dy > 4) {
            this.touchDir = DIR_Y;
          }
        }
        if (this.touchDir) {
          this.lastPoint = point;
        }
        if (this.touchDir === DIR_Y) {
          preventDefault = this.addPosY(delta.y);
        } else if (this.touchDir === DIR_X) {
          preventDefault = this.addPosX(delta.x);
        }
        // 在这里求速度，根据point 和 lastPoint所发生的时间和像素距离  x px / 100 ms
        if (this.touchDir) {
          const d =
            ((this.touchDir === DIR_X ? delta.x : delta.y) * 100) /
            (Date.now() - this.lastTouchEventTimpStamp);
          this.touchMoveVelocity = Math.floor(
            this.touchMoveVelocity * 0.3 + d * 0.7
          );
        }
        break;
      case "touchend":
        point = e.touches[0];
        delta = makeDelta(point, this.lastPoint);
        if (this.touchDir === DIR_Y) {
          this.addPosY(delta.y);
        } else if (this.touchDir === DIR_X) {
          this.addPosX(delta.x);
        }
        if (isFinite(this.touchMoveVelocity)) {
          this.startInertiaScroll(this.touchMoveVelocity, this.touchDir);
        }
        this.touchDir = null;
        this.touching = false;
        this.lastPoint = null;
        this.touchMoveVelocity = null;
        break;
      case "touchcancel":
        this.touching = false;
        this.touchDir = null;
        break;
    }
    // 如果不阻止默认行为，则滑动列表时，整个页面也滑动。
    if (e.type === "touchmove" && preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  testInScrollbar(cx, cy) {
    if (!this.hasScrollbarV && !this.hasScrollbarH) {
      return 0;
    }
    const x = cx * this.pixelRatio;
    const y = cy * this.pixelRatio;
    if (this.scrollbarV) {
      const res = this.scrollbarV.testIn(x, y);
      if (+res === 1) {
        return IN_SCROLL_V; // 垂直滚动条区域
      } else if (+res === 2) {
        return IN_BAR_V; // 垂直滚动条滑块
      }
    }
    if (this.scrollbarH) {
      const res = this.scrollbarH.testIn(x, y);
      if (+res === 1) {
        return IN_SCROLL_H;
      } else if (+res === 2) {
        return IN_BAR_H;
      }
    }
    return 0;
  }

  // 带惯性的滚动
  inertiaScroll(v, dir) {
    // v = px / 5ms
    window.requestAnimationFrame((highT) => {
      // t ms
      const t = Math.floor(highT);
      if (!this.inertiaBeginTimeStamp) {
        this.inertiaBeginTimeStamp = t;
      }
      if (!this.lastInertiaTimeStamp) {
        this.lastInertiaTimeStamp = t;
      }
      const deltaT = (t - this.lastInertiaTimeStamp) / 100; // 转为100ms单位
      const c = attenuationCoefficient(
        Math.abs(v),
        t - this.inertiaBeginTimeStamp
      );
      const s = Math.floor(deltaT * v * c * 8); // 乘以一个以时间和初始速度相关的衰减值, *5
      if (Math.abs(s) >= 1) {
        this.lastInertiaTimeStamp = t;
        const ok = dir === DIR_X ? this.addPosX(s) : this.addPosY(s);
        if (!ok) {
          this.inertia = false;
        }
      } else if (deltaT !== 0) {
        this.inertia = false;
      }
      if (this.inertia) {
        this.inertiaScroll(v, dir);
      } else {
        this.inertiaBeginTimeStamp = null;
        this.lastInertiaTimeStamp = null;
        this.touchDir = null;
      }
    });
  }

  startInertiaScroll(delta, dir) {
    this.inertia = true;
    this.inertiaScroll(delta, dir);
  }

  stopInertiaScroll() {
    this.inertia = false;
  }

  // dir = DIR_X / DIR_Y
  setScrollbarPosition(px, dir) {
    const d = px * this.pixelRatio;
    let offsetPx;
    if (dir === DIR_Y) {
      if (this.scrollbarV) {
        const scrollTop = this.scrollbarV.setPosition(0, d);
        offsetPx = Math.floor(scrollTop / this.pixelRatio);
        this.addPosY(offsetPx - this.posY);
      }
    } else if (dir === DIR_X) {
      if (this.scrollbarH) {
        const scrollLeft = this.scrollbarH.setPosition(d, 0);
        offsetPx = Math.floor(scrollLeft / this.pixelRatio);
        this.addPosX(offsetPx - this.posX);
      }
    }
  }

  addPosX(delta) {
    let ok = true;
    this.posX += delta;
    if (this.posX < 0) {
      this.posX = 0;
      ok = false;
    }
    if (this.posX > this.C_MAX_POS_X) {
      this.posX = this.C_MAX_POS_X;
      ok = false;
    }
    this.requestRefresh();
    return ok;
  }

  addPosY(delta) {
    let ok = true;
    this.posY += delta;
    if (this.posY < 0) {
      this.posY = 0;
      ok = false;
    }
    if (this.posY > this.C_MAX_POS_Y) {
      this.posY = this.C_MAX_POS_Y;
      ok = false;
    }
    this.requestRefresh();
    return ok;
  }

  /**
   * @description: 渲染滚动条
   * @return {*}
   */
  renderScrollbar() {
    // 画交界区域
    if (this.hasScrollbarV && this.hasScrollbarH) {
      const barWidth = this.option.scrollbarWidth;
      const ctx = this.ctx;
      // ctx.fillStyle = this.option.scrollbarBackgroundColor || theme.SCROLL_BACKGROUND_COLOR
      ctx.fillStyle = "#fff";
      ctx.fillRect(this.viewWidth - 2, this.viewHeight, barWidth, barWidth);
    }
    // 垂直滚动条
    if (this.hasScrollbarV) {
      this.scrollbarV.scrollTop = this.top;
    }
    // 水平滚动条
    if (this.hasScrollbarH) {
      this.scrollbarH.scrollLeft = this.left;
    }
  }

  translateContext(ctx, id) {
    const { top, left, fixedWidth, headHeight } = this;
    ctx.moveTo(0, 0);
    switch (id) {
      case TABLE_BODY_FIXED:
        ctx.translate(0, -top + headHeight);
        return;
      case TABLE_BODY || FORWARD:
        ctx.translate(-left + fixedWidth, -top + headHeight);
        return;
      case TABLE_HEAD_FIXED:
        ctx.translate(0, 0);
        return;
      case TABLE_HEAD:
        ctx.translate(-left + fixedWidth, 0);
        return;
      default:
        return {};
    }
  }

  /**
   * @description: 绘制表格内容部分，不含任务部分
   * @return {*}
   */
  renderTableBody(rect, cols, canvasId) {
    const {
      list,
      color,
      rowHeight,
      borderColor,
      borderWidth
    } = this.option;
    const { ctx } = this;
    let x = 0;
    let y = 0;
    ctx.save();
    // 盖一层底色
    ctx.fillStyle = "#fff";
    if (translateX && !this.option.onlyTable) {
      ctx.fillRect(
        rect.x,
        rect.y,
        translateX,
        rect.h + this.option.scrollbarWidth
      );
      ctx.rect(rect.x, rect.y, translateX, rect.h);
    } else {
      ctx.fillRect(rect.x, rect.y, rect.w, rect.h + this.option.scrollbarWidth);
      ctx.rect(rect.x, rect.y, rect.w, rect.h);
    }

    ctx.clip();
    this.translateContext(ctx, canvasId);
    const bodyTop = this.top;
    const bodyBottom = this.top + this.viewHeight - this.headHeight;
    const maxShowLen =
      Math.floor((this.viewHeight - this.headHeight) / rowHeight) + 1;
    const minShowLen = Math.floor(this.posY / rowHeight);
    list.forEach((row, index) => {
      x = 0;
      if (y + rowHeight < bodyTop || y > bodyBottom + rowHeight) {
        y += rowHeight;
        return;
      }
      cols.forEach((col) => {
        let fontSize = this.option.fontSize;
        let textColor = color;
        let text;
        let width = col.widthItem;
        // onlyTable模式指定的col宽度为auto 或者拖拽超过原来的宽度
        if (
          (this.option.onlyTable && col.widthAuto && !this.option.tableFixed) ||
          (!this.option.onlyTable &&
            col.widthAuto &&
            baseFixedWidth < this.fixedWidth)
        ) {
          let surplusWidth = 0;
          cols.forEach((dd, j) => {
            if (!dd.widthAuto) {
              surplusWidth += dd.widthItem;
            }
            if (j === cols.length - 1) {
              if (baseFixedWidth < this.fixedWidth) {
                col.width =
                  col.widthItem =
                  width =
                    this.fixedWidth - surplusWidth;
              } else {
                col.width =
                  col.widthItem =
                  width =
                    this.canvasWidth - surplusWidth;
              }
            }
          });
        }
        if (isFunction(col.draw)) {
          const ok = col.draw(
            row,
            ctx,
            new Rect(x, y, width, rowHeight),
            this.designScale
          );
          if (!ok) {
            this.waitingRender = true;
          }
        } else {
          if (isFunction(col.formatter)) {
            text = col.formatter(row);
          } else {
            text = row[col.field];
          }
          // 时间格式YYYY-MM-DD
          if (col.format) {
            if (text) {
              if (col.format === "Date") {
                if (text === "0000-00-00 00:00:00") {
                  text = "-";
                } else if (
                  col.field === "daily_end_date" ||
                  col.field === "actual_end_date"
                ) {
                  if (
                    Number(row.daily_over) >= 1 ||
                    col.field === "actual_end_date"
                  ) {
                    text = parseTime(text, "{y}-{m}-{d}");
                  } else {
                    text = "-";
                  }
                } else {
                  text = parseTime(text, "{y}-{m}-{d}");
                }
              } else if (col.format === "Number") {
                text = Number(text).toFixed(2);
              } else if (col.format === "Percent") {
                // 百分比
                text = (text * 100).toFixed(2) + "%";
              }
            } else {
              text = "-";
            }
          }
          if (isObject(text)) {
            const o = text;
            text = o.text;
            textColor = o.color || textColor;
            fontSize = o.fontSize || fontSize;
          }
          // 只渲染可视范围内得数据
          if (index <= maxShowLen + minShowLen && index >= minShowLen) {
            drawTextInTable(
              ctx,
              text || "-",
              x,
              y,
              width,
              rowHeight,
              {
                fontSize,
                color: textColor,
                align: col.treeIcon ? "left" : col.align,
                borderColor,
                borderWidth,
                backgroundColor: index % 2 === 0?'#fff':'#F9FBFD',
              },
              row,
              col
            );
            
            // 这个格子的xy坐标存入，方便行内编辑显示输入框
            col.positionX = x;
            row.positionY = y;
          }
        }
        x += width;
      });
      y += rowHeight;
    });
    ctx.restore();
  }

  /**
   * @description: 绘制任务部分
   * @return {*}
   */
  renderTaskBody(rect,canvasId) {
    const { list, color, rowHeight, fontSize, maxHeight } = this.option;
    const { ctx } = this;
    let y = 0;
    ctx.save();
    ctx.rect(rect.x, rect.y, rect.w, rect.h);
    this.translateContext(ctx, canvasId);
    const bodyTop = this.top;
    const bodyBottom = this.top + this.viewHeight - this.headHeight;
    const maxShowLen = Math.floor(
      (this.viewHeight - this.headHeight) / rowHeight
    );
    const minShowLen = Math.floor(this.posY / rowHeight);
    if (!list.length) {
      const noTodoImg = document.getElementById('nodata');
      ctx.beginPath();
      ctx.fillStyle = '#A4ACBD';
      ctx.font = '14px Georgia';
      ctx.fillText('暂无待办', this.canvasWidth / 2 - 20, maxHeight/2);
      ctx.save();
      ctx.drawImage(noTodoImg, this.canvasWidth / 2 - 5, maxHeight/2 - 50, 25, 25);
      ctx.stroke();
      ctx.restore();
    }
    ctx.fillStyle = color;
    list.forEach((row, index) => {
      if (y + rowHeight < bodyTop || y > bodyBottom) {
        y += rowHeight;
        return;
      }
      row.taskY = y + 56;
      // 只渲染可视范围内得数据
      if (index <= maxShowLen + minShowLen && index >= minShowLen) {
        drawTask(ctx, row.taskX, y + 14,row.taskWidth, rowHeight - 28, 4, {
          borderWidth: 1,
          color: color,
          borderColor: "transparent",
          backgroundColor: '#E7F0FE',
        },row);
        // 绘制任务名称
        if (row.taskWidth) {
          drawTextInTask(
            ctx,
            row.name,
            row.taskX,
            y + 4,
            row.taskWidth,
            rowHeight - 15,
            {
              fontSize: fontSize,
              align: this.option.ganttAlign || "center",
              color: color,
            }
          );
        }
      }
      y += rowHeight;
    });
    // 外底部边线
    ctx.lineCap = "round";
    ctx.lineJoin = "miter";
    ctx.strokeStyle = this.option.borderColor;

    ctx.beginPath();
    ctx.lineWidth = 0.5;
    // 0.5是解决绘制线条出现2px的问题
    ctx.moveTo(0, y - 0.5);
    ctx.lineTo(this.tableWidth, y - 0.5);
    ctx.stroke();
    ctx.restore();
  }

  /**
   * @description: 绘制任务部分的条纹背景
   * @return {*}
   */
  renderTaskBg(rect, list,cols, canvasId) {
    const { borderColor, rowHeight, } = this.option
    const { ctx } = this;
    const maxShowLen =  Math.floor((this.viewHeight - this.headHeight) / rowHeight) + 1;
    const minShowLen = Math.floor(this.posY / rowHeight);
    let y = 0;
    let x = 0;
    ctx.save();
    ctx.rect(rect.x, rect.y, rect.w, rect.h);
    ctx.clip();
    this.translateContext(ctx, canvasId);
    list.forEach((col,index) => {
      // 只渲染可视范围内得数据
      if (index <= maxShowLen + minShowLen && index >= minShowLen) {
        drawBgInTaskBg(ctx,0 , y, tableContentWidth, rowHeight, index % 2 === 0?'#fff':'#F9FBFD',borderColor);
      }
      y += rowHeight;
    });
    if (list.length) {
      cols.forEach((col,index) => {
        // 只渲染可视范围内得数据
        ctx.beginPath();
        ctx.lineWidth = 1;
        if(index === 0){
          ctx.lineWidth = 0;
        }
        ctx.strokeStyle = '#e6e6e6';
        ctx.setLineDash([4]);
        ctx.moveTo(x - 0.5, 0 + this.posY);
        ctx.lineTo(x, this.viewHeight + this.posY);
        ctx.stroke();
        x += col.widthItem;
      });
    }
    ctx.restore();
  }

  /**
   * @description: 渲染header
   * @return {*}
   */
  renderHead(rect, cols, canvasId) {
    const {
      headColor,
      headHeight,
      borderWidth,
      headBackgroundColor,
    } = this.option;
    const { ctx } = this;
    let x = 0;
    const y = 0;
    ctx.clearRect(rect.x, rect.y, rect.w, rect.h);
    ctx.save();
    ctx.rect(rect.x, rect.y, rect.w, rect.h);
    ctx.clip();
    ctx.fillStyle = headBackgroundColor;
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    this.translateContext(ctx, canvasId);
    cols.forEach((col) => {
      let fontSize = this.option.fontSize;
      let textColor = headColor;
      let text = col.title;
      let width = col.widthItem;
      let height = headHeight;
      // 仅table滞后有widthAuto的宽度变为auto
      if (this.option.onlyTable && col.widthAuto && !this.option.tableFixed) {
        let surplusWidth = 0;
        cols.forEach((dd, j) => {
          if (!dd.widthAuto) {
            surplusWidth += dd.widthItem;
          }
          if (j === cols.length - 1) {
            col.width = col.widthItem = width = this.canvasWidth - surplusWidth;
          }
        });
      }
      // handlehe 是操作得标识，有children，但header不分成上下,location是解绑和绑定操作
      if (
        col.children?.length &&
        col.field !== "handle" &&
        col.field !== "location"
      ) {
        height = headHeight / 2;
      }
      if (isObject(text)) {
        const o = text;
        text = o.text;
        textColor = o.color || textColor;
        fontSize = o.fontSize || fontSize;
      }
      let bol = false;
      // 只显示可视范围内的header数据
      if (col.field === "itemDate") {
        col.childRender = true;
        if (x < this.canvasWidth - this.fixedWidth + this.posX) {
          if (this.posX === 0) {
            bol = true;
            col.childRender = true;
          } else {
            if (
              x - this.posX + width >= 0 &&
              x - this.posX <= this.canvasWidth - this.fixedWidth
            ) {
              bol = true;
              col.childRender = true;
            } else {
              bol = false;
              col.childRender = false;
            }
          }
        } else {
          bol = false;
          col.childRender = false;
        }
      }

      if (col.field !== "itemDate" || bol) {
        drawTextInHeader(
          ctx,
          text,
          x,
          y,
          width,
          height,
          {
            ...this.option,
            fontSize,
            color: textColor,
            align: col.titleAlign || col.align,
            borderColor: this.option.borderColor,
            borderWidth,
            backgroundColor: headBackgroundColor,
            pixelRatio: this.pixelRatio,
            
          },
          col
        );
      }
      x += width;
    });
    ctx.restore();
  }

  /**
   * @description: 移入效果
   * @return {*}
   */
  renderTaskHoverBg(rect, id) {
    const { list, rowHeight, headHeight } = this.option;
    const { ctx } = this;
    ctx.restore();
    const index = list.findIndex((v) => v.id === id);
    const hoverY = index * rowHeight - this.posY + headHeight;
    if (hoverY >= headHeight) {
      ctx.fillStyle = "rgba(128,128,128,0.2)";
      ctx.fillRect(rect.x, hoverY, rect.w, rowHeight);
    }
  }


  /**
   * @description: 绘制今天
   * @return {*}
   */
  rendTimeLine(rect, canvasId ) {
    const { headHeight, viewHeight, fixedWidth, ctx } = this;
    const now = new Date()
    //当前时间转为分钟数
    const totalMinutes = Math.floor((now - new Date(now.getFullYear(), now.getMonth(), now.getDate())) / 60000);
    const lineX = Math.ceil(fixedWidth + totalMinutes - this.posX * this.pixelRatio);
    todayLinePosition = [lineX, 50];
    ctx.save();
    ctx.fillStyle = "transparent";
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    this.translateContext(ctx, canvasId);
    // 渲染绿色的竖线
    if (lineX > fixedWidth) {
      ctx.strokeStyle = "#ED474A";
      ctx.beginPath();
      ctx.moveTo(lineX + 0.5, headHeight);
      ctx.lineTo(lineX + 0.5, viewHeight);
      ctx.stroke();
      ctx.beginPath();
      ctx.fillStyle = "#ED474A";
      ctx.arc(lineX + 0.5, headHeight + 4.5, 4, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
    }
  }

  /**
   * @description: 绘制分割虚线
   * @return {*}
   */
  rendDashedLine(rect, cols, canvasId, lineDate) {
    const y = this.option.headHeight;
    const { headHeight, viewHeight, fixedWidth, ctx, viewWidth } = this;
    let firstDate = this.option.startDate;
    if (cols?.length) {
      if (cols[0].children?.length) {
        firstDate = cols[0].children[0].fullDate;
      } else {
        firstDate = cols[0].fullDate;
      }
    }
    const lineStartBetween = getDaysBetween(firstDate, lineDate);
    const lineX = Math.ceil(
      fixedWidth +
        lineStartBetween * baseItemWidth -
        this.posX * this.pixelRatio
    );
    // 前锋线需要展示在可视的位置
    if (
      dashedLinePosition[0] === 10000 &&
      (lineX > viewWidth || lineX < fixedWidth)
    ) {
      if (lineX < fixedWidth) {
        this.posX = viewWidth - lineX;
      } else {
        const posX = lineX - viewWidth + (viewWidth - fixedWidth) / 2;
        this.posX = Math.min(this.C_MAX_POS_X, posX);
      }
    }
    dashedLinePosition = [lineX, 50];
    ctx.save();
    ctx.fillStyle = "transparent";
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    this.translateContext(ctx, canvasId);
    // 渲染绿色的竖线
    if (lineX > fixedWidth) {
      ctx.strokeStyle = "rgba(0,179,178,0.4)";
      ctx.beginPath();
      ctx.setLineDash([4]);
      ctx.moveTo(lineX, headHeight);
      ctx.lineTo(lineX, viewHeight);
      ctx.stroke();
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.fillStyle = "rgba(0,179,178,0.4)";
      ctx.lineWidth = 0;
      ctx.beginPath();
      ctx.arcTo(lineX, y, lineX + 85, y, 4);
      ctx.arcTo(lineX + 85, y, lineX + 90, y + 23, 4);
      ctx.arcTo(lineX + 85, y + 23, lineX, y + 23, 4);
      ctx.arcTo(lineX, y + 23, lineX, y, 0);
      ctx.arcTo(lineX, y, lineX + 85, y, 0);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
      ctx.beginPath();
      ctx.font = "13px 微软雅黑";
      ctx.fillStyle = "#fff";
      ctx.fillText(lineDate, lineX + 6, 56);
      // 拖拽线绘制
      if (dashedLineDateNum) {
        ctx.beginPath();
        ctx.moveTo(lineX - 4, this.option.headHeight);
        ctx.lineTo(lineX - 4, this.option.headHeight + 23);
        ctx.stroke();
      }
    }
  }

  /**
   * @description: 绘制各个部分的内容
   * @return {*}
   */
  renderSubView() {
    // canvas没有层级，只有先后绘制，这里的绘制顺序很重要
    let rect;
    const { normalCols, fixedCols, tableFixed, list, dateType } = this.option;
    const { headHeight, fixedWidth, viewWidth, viewHeight, ctx } = this;
    ctx.clearRect(0, 0, viewWidth, viewHeight);
    // 周月默认滚动到今天,只在初始化执行一次
    if (!this.option.onlyTable && dateType !== 'day' && this.hasScrollbarH && this.option.defaultScrollLeft > 0) {
      const posX = this.option.defaultScrollLeft - viewWidth + (viewWidth - fixedWidth) / 2;
      this.posX = Math.min(this.C_MAX_POS_X, posX);
      this.option.defaultScrollLeft = 0;
    }
    if (!this.option.onlyTable) {
      // 绘制任务条条纹背景
      const taskBgRect = {
        x: fixedWidth,
        y: headHeight,
        w: viewWidth - fixedWidth,
        h: viewHeight - headHeight,
      };
      this.renderTaskBg(taskBgRect, list, normalCols, TABLE_BODY);
      // 渲染任务条
      if (this.option.onlyGantt) {
        rect = {
          x: 0,
          y: headHeight,
          w: viewWidth,
          h: viewHeight - headHeight,
        };
      } else {
        rect = {
          x: fixedWidth,
          y: headHeight,
          w: viewWidth - fixedWidth,
          h: viewHeight - headHeight,
        };
      }
      this.renderTaskBody(rect, TABLE_BODY);
    }
    // 渲染当前时刻
    if (!this.option.onlyTable && dateType === 'day'  && this.option.list?.length) {
      rect = {
        x: fixedWidth,
        y: headHeight,
        w: viewWidth - fixedWidth,
        h: viewHeight - headHeight,
      };
      this.rendTimeLine(rect, FORWARD);
    }
    // 渲染拖拽的虚线
    if (dashedLineDateNum) {
      rect = {
        x: fixedWidth,
        y: headHeight,
        w: viewWidth - fixedWidth,
        h: viewHeight - headHeight,
      };
      this.rendDashedLine(rect, normalCols, FORWARD, dashedLineDateNum);
    }
    // 渲染左侧的表格数据
    if (!this.option.onlyGantt) {
      // 全表格宽度低于1500才渲染右侧是滚动的状态
      if (tableFixed) {
        const tableFixedData = normalCols.filter((v) => v.field !== "itemDate");
        if (tableFixedData?.length) {
          const rectFixedTable = {
            x: fixedWidth,
            y: headHeight,
            w: viewWidth - fixedWidth,
            h: viewHeight - headHeight,
          };
          const rectFixedHead = {
            x: fixedWidth,
            y: 0,
            w: this.canvasWidth - fixedWidth,
            h: headHeight,
          };
          this.renderTableBody(rectFixedTable, tableFixedData, TABLE_BODY);
          this.renderHead(rectFixedHead, tableFixedData, TABLE_HEAD);
        }
      }
      rect = { x: 0, y: headHeight, w: fixedWidth, h: viewHeight - headHeight };
      this.renderTableBody(rect, fixedCols, TABLE_BODY_FIXED);
    }
    if (!this.option.onlyTable) {
      if (this.option.onlyGantt) {
        rect = { x: 0, y: 0, w: viewWidth, h: headHeight };
        this.renderHead(rect, normalCols, TABLE_HEAD);
      } else {
        rect = {
          x: fixedWidth,
          y: 0,
          w: this.canvasWidth - fixedWidth,
          h: headHeight,
        };
        this.renderHead(rect, normalCols, TABLE_HEAD);
      }
      // 渲染时间轴的旗子
      if (this.option.isPlayAble) {
        if (flagPosition[0] === 0 && flagPosition[1] === 0) {
          flagPosition = [rect.x, rect.y];
        }
      }
    }
    // 渲染header
    if (!this.option.onlyGantt) {
      rect = { x: 0, y: 0, w: fixedWidth, h: headHeight };
      this.renderHead(rect, fixedCols, TABLE_HEAD_FIXED);
    }
    
    // hover效果
    // if (hoverId) {
    //   this.renderTaskHoverBg(
    //     {
    //       x: 0,
    //       y: headHeight,
    //       w: this.canvasWidth,
    //       h: viewHeight - headHeight,
    //     },
    //     hoverId
    //   );
    // }
  }

  async init(option) {
    if (!option || typeof option !== "object") {
      console.log(`CanvasTable: option Invalid: ${option}`);
    }
    if (!(option.cols instanceof Array)) {
      console.log(`CanvasTable: option.cols Invalid: ${option.cols}`);
    }
    if (!(option.list instanceof Array)) {
      console.log(`CanvasTable: option.list Invalid: ${option.list}`);
    }
    // console.log(this, "option");
    this.option = option;
    this.initOption(option);
    if (!todayDataNum) {
      const todayDate = parseTime(new Date(), "{y}-{m}-{d}");
      if (todayDate > option.startDate && todayDate < option.endDate) {
        todayDataNum = todayDate;
      }
    }
    // 获取拖拽和播放的初始时间
    if (option.isPlayAble && option?.normalCols?.length) {
      if (option.normalCols[0].children?.length) {
        flagDateNum = option.normalCols[0].children[0].fullDate;
        firstDateNum = option.normalCols[0].children[0].fullDate;
      } else {
        flagDateNum = option.normalCols[0].fullDate;
        firstDateNum = option.normalCols[0].fullDate;
      }
    }
    // 监听shift按键
    document.onkeydown = (e) => {
      if (e.key === "Shift" && !ShiftEnter) {
        ShiftEnter = true;
      }
    };
    document.onkeyup = () => {
      if (ShiftEnter) {
        ShiftEnter = false;
      }
    };
    window.requestAnimationFrame(this.draw.bind(this));
  }

  draw() {
    if (!this.requestFlag) {
      return;
    }
    const ctx = this.ctx;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.save();
    this.renderSubView();
    if(this.option.list?.length){
      this.renderScrollbar();
    }
    ctx.restore();
    this.requestFlag = false;
    if (this.waitingRender) {
      this.requestRefresh();
      this.waitingRender = false;
    }
  }
}
