<template>
    <div class="alert-detail-pop" @click="closePop">
        <div class="alert-detail" :class="{ zoomPop: isZoom }" @click.stop>
            <div class="alert-title">
                <span>系统公告</span>
                <div @click="zoomPopFun()">
                    <i class="iconfont fullscreen" :class="!isZoom ? 'iconquanping6' : 'icontuichuquanping1'"></i>
                </div>
                <div>
                    <img @click="closePop()"
                        src='https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8acf98a07bb16692fe62692859d76e2a.png' />
                </div>
            </div>
            <div :class="{ activeContent: setDeal }" class="alert-content">
                <popContentMain class="popContentMain" :itemType=2 :itemData="props.item"></popContentMain>
                <PopContentSider :itemType=2 :itemData="props.item" @on-handle="tochange" :isReply="isReply" @toSysItemDataIsUpdate="toSysItemDataIsUpdate"/>
                <div :class="{ activeDispose: setDeal }" class="alert-dispose" v-if="setDeal"
                    >
                    <div class="dispose-title">
                        <span>回复公文</span>
                        <div @click="cancelEdit()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M16.25 10.4365L11.5089 15.1776L10.625 14.2938L14.9242 9.99458L10.625 5.69541L11.5089 4.81152L16.25 9.55264C16.4941 9.79672 16.4941 10.1924 16.25 10.4365ZM9.37505 10.4365L4.63393 15.1776L3.75005 14.2938L8.04922 9.99458L3.75005 5.69541L4.63393 4.81152L9.37505 9.55264C9.61912 9.79672 9.61912 10.1924 9.37505 10.4365Z"
                                    fill="#8991A2" />
                            </svg>
                        </div>
                    </div>
                    <div class="dispose-main" :class="{ zoomDis: isZoom }">
                        <el-form label-position="top" :model="formLabelAlign" :rules="rules" ref="ruleForm">
                            <el-form-item label="回复对象" prop="repUser">
                                <UserList class="user-list" @on-select-change="onSelectUser" :isItself="true"></UserList>
                            </el-form-item>
                            <el-form-item label="内容" prop="textarea">
                                <el-input placeholder="请输入" type="textarea" style="width: 340px;"
                                    v-model="formLabelAlign.textarea"></el-input>
                            </el-form-item>
                            <el-form-item label="图片">
                                <fileUpload drag :fileTypeList="typeFile" style="width: 340px;"
                                    @extra-files="extraFiles1" :files="picList"></fileUpload>
                            </el-form-item>
                            <el-form-item label="附件">
                                <fileUpload :files="attachList" :onlyoffice="false" drag
                                    style="width: 340px;height: 96px;" @extra-files="extraFiles2">
                                </fileUpload>
                            </el-form-item>
                        </el-form>

                    </div>
                    <div class="dispose-bottom">
                        <button @click="cancelEdit()" class="bnt-cancel">取消</button>
                        <button  @click="submitRey(ruleForm)" class="bnt-confirm">确定</button >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import PopContentSider from '../../popContentSider.vue';
import fileUpload from '../../../../../components/fileUpload/newFiles/index.vue'
import UserList from "../../../../../custom-component/form/formParser/selectUser/index.vue"
import popContentMain from '../../popContentMain.vue';
import { ref, defineProps,inject } from 'vue';
import { Message } from 'element-ui';
import { dataInterface } from '@/apis/data';

const formLabelAlign = ref({
    textarea: "",
    repUser: [],
    picList: [],
    attachList: []
});
const ruleForm = ref();
const picList = ref([]);
const attachList = ref([]);
const setDeal = ref(false);
const isReply = ref(false)
const isZoom = ref(false);

const emits = defineEmits(["close-pop"]);
const toUpdate = inject(['toUpdate']);
const toSysItemDataIsUpdate = () => {
    toUpdate(false, true)
    Message({
        message: '操作成功',
        type: 'success'
    })
}

const props = defineProps({
    item: {
        type: Object,
        default: () => {}
    }
})

const rules = {
    textarea: [
        { required: true, message: '请输入内容', trigger: 'blur' }
    ],
    repUser: [
        { required: true, message: '请选择人员', trigger: 'blur' }
    ]

}

const onSelectUser = (arr) => {
    formLabelAlign.value.repUser = []
    arr.forEach(item => {
        formLabelAlign.value.repUser.push({
            id: item.id,
            name: item.name,
            user_table: item.user_table,
            user_id: item.user_id,
            section_id: item.section_id
        })
    })
}

const extraFiles1 = (p) => {
    formLabelAlign.value.picList = []
    p.forEach(item => {
        formLabelAlign.value.picList.push({
            file_md5: item.file_md5,
            filesize: item.filesize,
            filetype: item.filetype,
            fileext: item.fileext,
            filename: item.filename,
            filepath: item.filepath,
            thumb_path: item.thumb_path,
            user_id: item.user_id,
            user_name: item.user_name,
            role_id: item.role_id,
            role_name: item.role_name,
            archi_id: item.archi_id,
            ip: item.ip,
            updated_at: item.updated_at,
            created_at: item.created_at,
            id: item.id,
            url: item.url,
            upTime: item.upTime,
            name: item.name,
            path: item.path
        })
    })
    picList.value = p
}

const extraFiles2 = (p) => {
    formLabelAlign.value.attachList = []
    p.forEach(item => {
        formLabelAlign.value.attachList.push({
            file_md5: item.file_md5,
            filesize: item.filesize,
            filetype: item.filetype,
            fileext: item.fileext,
            filename: item.filename,
            filepath: item.filepath,
            thumb_path: item.thumb_path,
            user_id: item.user_id,
            user_name: item.user_name,
            role_id: item.role_id,
            role_name: item.role_name,
            archi_id: item.archi_id,
            ip: item.ip,
            updated_at: item.updated_at,
            created_at: item.created_at,
            id: item.id,
            url: item.url,
            upTime: item.upTime,
            name: item.name,
            path: item.path
        })
    })
    attachList.value = p
}

//确定
const submitRey = (formEl) => {
    formEl.validate((validate) => {
        if (validate) {
            dataInterface({
                __method_name__: "updateData",
                object_uuid: "object6502ac1128ecd",
                replay_object: formLabelAlign.value.repUser,
                main_text: formLabelAlign.value.textarea,
                pic: formLabelAlign.value.picList,
                attachment: formLabelAlign.value.attachList,
                data_id: props.item.announcements_son_id
            }).then(res => {
                if (res.data.code === 200) {
                    Message({
                        message: '操作成功',
                        type: 'success'
                    })
                    setDeal.value = false;
                    isReply.value = !isReply.value;
                }
            })
        }
    })

}

// 点击处理按钮
const tochange = () => {
    setDeal.value = true;
}

// 开关弹窗
const closePop = () => {
    emits('close-pop');
}

//取消
const cancelEdit = () => {
    setDeal.value = false;
}

//类型限制
const typeFile = ref(["png", "jpg", "jpeg", "gif"]);

//弹窗缩放
const zoomPopFun = () => {
    isZoom.value = !isZoom.value;
}
</script>

<style lang="less" scoped>
.alert-detail-pop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 10;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    // overflow: hidden;
    .zoomPop {
        display: flex;
        width: 100% !important;
        height: 100% !important;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: var(--Radio-16, 16px);
        background: var(--overall-surface-default, #FFF);
        overflow: hidden;
    }

    .alert-detail {
        display: flex;
        width: 960px;
        height: 720px;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: var(--Radio-16, 16px);
        background: var(--overall-surface-default, #FFF);
        overflow: hidden;

        // color: white;
        .alert-title {
            display: flex;
            height: 56px;
            padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
            align-items: center;
            gap: 12px;

            flex-shrink: 0;
            align-self: stretch;
            border-bottom: 1px solid var(--border-on-surface-weak, #F0F1F4);

            span {
                color: var(--text-on-surface-primary, #181B22);
                font-family: "MiSans VF";
                font-size: 16px;
                font-style: normal;
                font-weight: 380;
                line-height: 24px;
                /* 150% */
                flex: 1 0 0;

            }

            div {
                border-radius: 5px;

                img {
                    display: flex;
                    align-items: flex-start;
                    border-radius: var(--radius-2, 4px);
                    width: 32px;
                }
            }

            .fullscreen {
                font-size: 22px;
                color: #4D535E;
            }

            // div:hover {
            //     background-color: #F4F6F9;
            // }

            // div:active {
            //     background-color: #b8becce3;
            // }

        }

        .alert-content {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            position: relative;
            height: calc(100% - 57px);
            .alert-dispose {
                display: flex;
                background-color: white;
                width: 400px;
                height: 100%;
                flex-direction: column;
                align-items: flex-start;
                position: absolute;
                right: -400px;
                top: -1px;
                border-left: 1px solid var(--border-on-surface-weak, #F0F1F4);
                border-top: 1px solid var(--border-on-surface-weak, #F0F1F4);
                box-sizing: border-box;

                .dispose-title {
                    display: flex;
                    height: 56px;
                    padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
                    align-items: center;
                    gap: 8px;
                    flex-shrink: 0;
                    align-self: stretch;

                    span {
                        color: var(--text-on-surface-primary, #181B22);
                        font-family: "MiSans VF";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 380;
                        line-height: 24px;
                        /* 150% */
                        flex: 1 0 0;
                    }

                    img {
                        display: flex;
                        width: var(--button-list-field-medium, 32px);
                        height: var(--button-list-field-medium, 32px);
                        justify-content: center;
                        align-items: center;
                    }
                }

                .zoomDis {
                    display: flex;
                    padding: 0px var(--spacing-12, 24px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    flex: 1 0 0;
                    align-self: stretch;
                    max-height: 777px !important;
                    overflow-x: hidden;
                }

                .dispose-main {
                    display: flex;
                    padding: 0px var(--spacing-12, 24px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    flex: 1 0 0;
                    align-self: stretch;
                    max-height: 544px;
                    overflow-x: hidden;

                    .el-form {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;
                        flex: 1 0 0;

                        :deep(.el-form-item__label) {
                            padding: 0px
                        }

                        :deep(.el-input__prefix) {
                            left: 0px;
                        }
                    }

                    .el-form-item {
                        margin: 0;

                        .user-list {
                            width: 340px;
                        }

                        ::v-deep {
                            .el-textarea__inner:hover {
                                border-color: rgb(31, 115, 241);
                            }
                        }

                        ::v-deep(.el-input__prefix) {
                            .show-value:hover {
                                border-color: rgb(31, 115, 241);
                            }

                            .show-value {
                                overflow: hidden !important;
                                text-overflow: ellipsis !important;
                                white-space: nowrap !important;
                            }
                        }

                        ::v-deep(.drag-img) {
                            .drag-img-item:hover {
                                border-color: rgb(31, 115, 241);
                            }
                        }
                    }

                    ::v-deep(.el-upload-dragger) {
                        height: 110px;
                    }
                }

                .dispose-bottom {
                    display: flex;
                    padding: 16px var(--spacing-10, 20px);
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--spacing-6, 12px);
                    align-self: stretch;
                    background-color: white;
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    width: -webkit-fill-available;

                    .bnt-cancel {
                        display: flex;
                        height: 32px;
                        padding: 0px var(--spacing-16, 32px);
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-3, 6px);
                        border-radius: var(--radius-3, 6px);
                        background: var(--_button-neutral-tertiary-background, #F0F1F4);
                    }

                    .bnt-cancel:hover {
                        background-color: #dfe6ec;
                    }

                    .bnt-cancel:active {
                        background-color: #b8becce3;
                    }

                    .bnt-confirm {
                        display: flex;
                        height: 32px;
                        padding: 0px var(--spacing-16, 32px);
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-3, 6px);
                        border-radius: var(--radius-3, 6px);
                        background: var(--themeColor);
                        color: #FFFFFF;
                    }
                }
            }

            .activeDispose {
                display: flex;
                background-color: white;
                width: 400px;
                height: 100%;
                flex-direction: column;
                align-items: flex-start;
                position: absolute;
                right: 0px;
                top: -1px;
                border-left: 1px solid var(--border-on-surface-weak, #F0F1F4);
                border-top: 1px solid var(--border-on-surface-weak, #F0F1F4);
                box-sizing: border-box;
            }

            .right-sider {
                display: flex;
                width: 200px;
                height: 664px;
                padding: 48px var(--spacing-16, 32px) 48px 0px;
                flex-direction: column;
                align-items: center;
                gap: var(--spacing-16, 32px);
                position: absolute;
                box-sizing: border-box;
                right: 0px;
            }
        }

        .activeContent {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            position: relative;
        }

    }
}
</style>