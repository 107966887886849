<template>
  <div class="shortcut-menu">
    <div class="shortcut-menu-title">
      <div class="title-left">
        <div class="title-icon">
          <img
            class="icon"
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/f27d707d9270bd6b27c435233b196345.png"
          />
        </div>
        <span class="title"> 快捷菜单 </span>
      </div>
      <div class="title-setting" @click="openMenuPopups">
        <img
          class="setting"
          src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/4c77a766473000fe28a3d5c998e1a0f2.png"
        />
      </div>
    </div>
    <div class="icon-list" v-loading="isLoading" v-if="menuList?.length">
      <div
        class="icon-item-box"
        v-for="item in menuList"
        :key="item?.id"
        @click="handleMenu(item)"
      >
        <div :class="{ icon: true }">
          <!-- <svg
            v-if="!item.shortcut_icon?.includes('el-icon')"
            aria-hidden="true"
            class="iconfont-svg"
            :style="{ fill: '#fff', width: 24, height: 24 }"
          >
            <use :xlink:href="'#' + item.shortcut_icon" />
          </svg> -->
          <i :class="{ iconfont: true, [item?.shortcut_icon]: true }"></i>
        </div>
        <div class="name">{{ item?.shortcut_name }}</div>
      </div>
    </div>
    <div class="empty" v-else>
      <i class="iconfont iconzanweipeizhi"></i>
      <div class="desc">暂无配置</div>
    </div>
    <ShortcutMenuPop
      :dialog-visible="dialogVisible"
      @close="handleCloseMenu"
      :menuList="id_list"
      :dataId="data_id"
    />
    <PagePop
      v-if="pageUUID"
      :dialog-visible="dialogPageVisible"
      @close="handleClosePage"
      :pageUUID="pageUUID"
      :width="proportion"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { onMounted, reactive, ref, watch, getCurrentInstance } from "vue";
import ShortcutMenuPop from "./ShortcutMenuPop.vue";
import { dataInterface } from "../../../../../apis/data";
import { getTargetArchi } from "@/utils/tools";
import PagePop from "../PagePop.vue";
const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");
  return vm.proxy.$store;
};
export default {
  components: {
    ShortcutMenuPop,
    PagePop,
  },
  setup() {
    const store = useStore();
    const menuList = ref([]);
    const dialogVisible = ref(false);
    const dialogPageVisible = ref(false);
    const pageUUID = ref("");
    const id_list = ref([]);
    const data_id = ref("");
    const isLoading = ref(false);
    const proportion = ref("50%");
    const openMenuPopups = () => {
      dialogVisible.value = true;
    };
    const handleCloseMenu = (refresh) => {
      dialogVisible.value = false;
      if (refresh) {
        getPersonalMenu();
      }
    };
    const handleClosePage = () => {
      dialogPageVisible.value = false;
    };
    const handleMenu = (item) => {
      if (item.shortcut_type === 1) {
        dialogPageVisible.value = true;
        pageUUID.value = item.shortcut_path;
        proportion.value = item.proportion;
      }
      if (item.shortcut_type === 2) {
        let data = {
          event: "showDetailModal",
          form_uuid: item.shortcut_path,
          object_name: item.shortcut_name,
          archi_type: getTargetArchi().data_id + "",
          show_footer: true,
          disabled: false,
          use_object_uuid: item.use_object_uuid,
          width: item.proportion,
        };
        window.parent.postMessage(data, "*");
      }
    };
    /**
     * @name:
     * @description: 获取快捷方式列表
     * @msg:
     * @return {*}
     */
    const getPersonalMenu = () => {
      isLoading.value = true;
      const params = {
        object_uuid: "object66a89f9fe28bd",
        view_uuid: "view66a8b1e26e52c",
        __method_name__: "dataList",
        transcode: 0,
      };
      dataInterface(params)
        .then((res) => {
          if (res.data.code === 200) {
            menuList.value = res.data.data[0]?.org_list_id
              .map((id) =>
                res.data.data[0]?.org_list.find((item) => item?.id === id)
              )
              .filter((item) => item);
            id_list.value = menuList.value.map((item) => item.id);
            data_id.value = res.data.data[0]?.id;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    onMounted(() => {
      getPersonalMenu();
    });
    return {
      menuList,
      dialogVisible,
      openMenuPopups,
      handleCloseMenu,
      isLoading,
      id_list,
      data_id,
      handleClosePage,
      dialogPageVisible,
      handleMenu,
      pageUUID,
      proportion,
    };
  },
};
</script>

<style lang="less" scoped>
.shortcut-menu {
  display: flex;
  min-height: 248px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--Radio-12, 12px);
  background: var(--overall-surface-default, #fff);
  flex-shrink: 0;
  .shortcut-menu-title {
    display: flex;
    height: 56px;
    box-sizing: border-box;
    padding: 10px var(--spacing-10, 20px);
    align-items: center;
    gap: 4px;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-on-surface-weak, #f0f1f4);

    .title-left {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .title-icon {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .icon {
        width: 36px;
        flex-shrink: 0;
      }
    }

    .title {
      color: var(--text-on-surface-primary, #181b22);
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: 24px;
      /* 150% */
    }

    .title-setting {
      display: flex;
      width: var(--button-list-field-small, 24px);
      height: var(--button-list-field-small, 24px);
      justify-content: flex-end;
      align-items: center;
      border-radius: var(--Radio-6, 6px);

      .setting {
        width: var(--Radio-16, 16px);
        height: var(--Radio-16, 16px);
        transform: rotate(-90deg);
        flex-shrink: 0;
      }
    }
  }

  .icon-list {
    display: flex;
    padding: 0px 8px;
    flex-wrap: wrap;

    .icon {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
    }
  }

  .title {
    color: var(--text-on-surface-primary, #181b22);
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    /* 150% */
  }

  .title-setting {
    display: flex;
    width: var(--button-list-field-small, 24px);
    height: var(--button-list-field-small, 24px);
    justify-content: flex-end;
    align-items: center;
    border-radius: var(--Radio-6, 6px);

    .setting {
      width: var(--Radio-16, 16px);
      height: var(--Radio-16, 16px);
      transform: rotate(-90deg);
      flex-shrink: 0;
    }
  }
  .icon-list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 12px 8px;
    align-content: baseline;
    width: -webkit-fill-available;
    .icon-item-box {
      width: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 9px 0;
      height: fit-content;
      gap: 4px;
      .icon {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background: var(--themeColor);
        align-items: center;
        justify-content: center;
        .iconfont {
          color: white;
          font-size: 24px;
        }
      }
      .svg-icon {
        background: transparent;
      }
      .name {
        color: var(--text-on-surface-secondary, #424751);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 256px;
    padding: 12px 8px;
    .iconfont {
      font-size: 42px;
      line-height: 48px;
      color: #a4acbd;
    }
    .desc {
      color: var(--text-on-surface-placeholder, #a4acbd);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}
</style>
