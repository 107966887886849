<!--
 * @Author: qinmengyuan
 * @Date: 2024-08-01 16:53:09
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-06 15:41:21
 * @FilePath: \dataview-viewer-test\src\views\todo\components\leftContent\FlowFinishPop.vue
-->
<template>
  <Dialog
    class="finish-modal"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :append-to-body="true"
    :fullscreen="fullscreen"
    :title="detail.user_name + '的' + detail.object_name + '流程'"
  >
    <div class="container">
      <i
        :class="{
          iconfont: true,
          iconquanping6: !fullscreen,
          icontuichuquanping1: fullscreen,
        }"
        @click="fullscreen = !fullscreen"
      ></i>
      <img
        src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/78a9790229319b06b6fef4118517bf4f.png"
        alt=""
      />
      <div class="success">提交成功</div>
      <div class="desc">{{ second }}s 后自动关闭弹窗，也可点击空白处关闭。</div>
      <div class="btn-cont">
        <el-button class="btn" type="info" plain @click="handleClose()"
          >关闭弹窗</el-button
        >
        <el-button class="btn" plain @click="handleNext"
          >处理下一条数据</el-button
        >
      </div>
    </div>
  </Dialog>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from "vue";
import { Dialog, Input, Checkbox, CheckboxGroup, Button } from "element-ui";
const second = ref(5);
const fullscreen = ref(false);
let timer;
const emits = defineEmits(["close", "open-next"]);
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  detail: {
    type: Object,
    default: {},
  },
});
const handleClose = () => {
  clearTimeout(timer);
  emits("close", true);
};
const handleNext = () => {
  clearTimeout(timer);
  emits("close");
  emits("open-next");
};
watch(
  () => props.dialogVisible,
  () => {
    second.value = 5;
    if (props.dialogVisible) {
      timer = setInterval(() => {
        second.value -= 1;
        if (second.value < 1) {
          clearTimeout(timer);
          emits("close", true);
        }
      }, 1000);
    } else {
      clearTimeout(timer);
    }
  }
);
</script>
<style lang="less" scoped>
.finish-modal {
  ::v-deep(.el-dialog) {
    border-radius: 16px;
    .el-icon-close {
      color: #4d535e;
    }
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .iconquanping6,
  .icontuichuquanping1 {
    position: absolute;
    right: 24px;
    top: -64px;
    color: #4d535e;
    &:hover {
      color: #409eff;
    }
  }
  .success {
    color: var(--text-on-surface-primary, #181b22);
    font-family: "MiSans VF";
    font-size: 24px;
    font-style: normal;
    font-weight: 330;
    line-height: 32px;
    margin: 20px 0 12px;
  }
  .desc {
    color: var(--text-on-surface-tertiary, #707786);
    text-align: center;
    font-family: "MiSans VF";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .btn-cont {
    padding: 24px;
    .btn {
      padding: 9px 32px;
      border-radius: 6px;
    }
  }
}
</style>
