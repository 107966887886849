<!--
 * @Author: qinmengyuan
 * @Date: 2024-07-31 16:51:16
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-16 11:08:13
 * @FilePath: \dataview-viewer-test\src\views\todo\components\rightCont\PagePop.vue
-->
<template>
  <Dialog
    class="page-modal"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    fullscreen
    custom-class="page-pop"
  >
    <div class="container">
      <i class="iconfont iconbiaotiguanbi close-icon" @click="handleClose"></i>
      <PageParser
        style="width: 100%; height: 100%"
        :key="pageUUID"
        :pageUUID="pageUUID"
      ></PageParser>
    </div>
  </Dialog>
</template>

<script setup>
/* eslint-disable */
import { Dialog } from 'element-ui';
import PageParser from '@/components/parser/Index';
const emits = defineEmits(['close']);
const props = defineProps({
  pageUUID: {
    type: String,
    default: '',
  },
  dialogVisible: {
    type: Boolean,
    default: false,
  },
});
const handleClose = () => {
  emits('close');
};
</script>
<style>
.page-pop {
  background: transparent;
}
.page-pop .el-dialog__header {
  display: none;
}
.page-pop .el-dialog__body {
  background: transparent;
  height: 100%;
  padding: 0;
}
</style>
<style lang="less" scoped>
.container {
  position: relative;
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  box-sizing: border-box;
  /* margin: 16px; */
  background: white;
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
  background-color: rgb(242, 244, 248);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .close-icon {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 2100;
    color: #4d535e;
    &:hover {
      color: #409eff;
    }
  }
  ::v-deep(.el-table) {
    width: 100% !important;
  }
}
</style>
