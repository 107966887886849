<template>
  <div v-loading="loading" ref="containerRef" class="alertItem">
    <div @mouseenter="mouseIn()" @mouseleave="mouseOut()">
      <div v-if="topLength <= 2 && alertIsScroll">
        <ul ref="listRef" v-if="dataBase?.length" id="list">
          <li v-for="(item, index) in dataBase.slice(0, topLength)" :key="index" @click="onShowDetail(item)"
            @mouseover="hoverItem(item.id, true)" @mouseout="outHoverItem()">
            <div class="alert-item-icon">
              <img class="item-icon" :src="getImg(item)">
            </div>
            <div class="alert-item-main">
              <div class="alert-item-title">
                <div v-if="listType === 'warn'" class="title">
                  <marquee v-if="item.id === hoverId">{{ item.warn_content }}</marquee>
                  <div v-else class="font-cont">
                    {{ item.warn_content }}
                  </div>
                </div>
                <div v-else class="title">
                  <div v-if="item.notice_type && item.announcement_source === 1" class="notice-item-type"
                    :class="getClass(item)">
                    [{{ item.notice_type }}]
                  </div>
                  <div v-if="item.notice_type && item.announcement_source === 2" class="notice-item-type"
                    :class="getClass(item)">
                    [系统]
                  </div>
                  <marquee v-if="item.id === hoverId">{{ item.title || item.warn_content }}</marquee>
                  <div v-else class="font-cont">{{ item.title || item.warn_content }}</div>
                  <svg class="to-top-bnt" v-if="item.is_to_top" xmlns="http://www.w3.org/2000/svg" width="14"
                    height="14" viewBox="0 0 14 14" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.2688 12.1152L3.74367 7.78414L1.47342 5.51389C1.30257 5.34304 1.30257 5.06603 1.47342 4.89517C1.56263 4.80597 1.68601 4.75961 1.81189 4.768L5.29047 4.99991L7.14662 3.14375L7.35546 1.26422C7.38214 1.02408 7.59845 0.851031 7.8386 0.877714C7.93734 0.888685 8.0294 0.932933 8.09964 1.00318L12.9995 5.90304C13.1704 6.0739 13.1704 6.35091 12.9995 6.52176C12.9293 6.59201 12.8372 6.63626 12.7385 6.64723L10.8589 6.85606L9.00278 8.71222L9.23468 12.1908C9.25076 12.4319 9.06835 12.6404 8.82726 12.6564C8.70138 12.6648 8.578 12.6185 8.48879 12.5293L6.21855 10.259L1.88752 12.7339L1.2688 12.1152Z"
                      fill="#BFC7D8" />
                  </svg>
                </div>
              </div>
              <div class="alert-item-other">
                <div class="item-name">
                  {{ listType !== 'notice' ? '项目：' : '' }}
                  {{ listType === 'notice' ? getFont(item.text) : (item.current_name || '-') }}
                </div>
                <div class="item-time">
                  {{ parseTime(item.created_at, '{m}-{d} {h}:{i}') }}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <el-carousel indicator-position="none" direction="vertical" arrow="never"
          :style="{ marginTop: `${topLength * 68}px` }">
          <el-carousel-item v-for="i in page" :key="i">
            <ul ref="listRef" v-if="dataBase?.length" id="list" :style="{ height: `${(3-topLength)*68}px` }">
              <li v-for="(item, index) in dataBase.slice((i - 1) * (pageSize-topLength)+topLength, i * (pageSize-topLength)+topLength)"
                :key="index" @click="onShowDetail(item)" @mouseover="hoverItem(item.id, true)"
                @mouseout="outHoverItem()">
                <div class="alert-item-icon">
                  <img class="item-icon" :src="getImg(item)">
                </div>
                <div class="alert-item-main">
                  <div class="alert-item-title">
                    <div v-if="listType === 'warn'" class="title">
                      <marquee v-if="item.id === hoverId">{{ item.warn_content }}</marquee>
                      <div v-else class="font-cont">
                        {{ item.warn_content }}
                      </div>
                    </div>
                    <div v-else class="title">
                      <div v-if="item.notice_type && item.announcement_source === 1" class="notice-item-type"
                        :class="getClass(item)">
                        [{{ item.notice_type }}]
                      </div>
                      <div v-if="item.notice_type && item.announcement_source === 2" class="notice-item-type"
                        :class="getClass(item)">
                        [系统]
                      </div>
                      <marquee v-if="item.id === hoverId">{{ item.title || item.warn_content }}</marquee>
                      <div v-else class="font-cont">{{ item.title || item.warn_content }}</div>
                      <svg class="to-top-bnt" v-if="item.is_to_top" xmlns="http://www.w3.org/2000/svg" width="14"
                        height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.2688 12.1152L3.74367 7.78414L1.47342 5.51389C1.30257 5.34304 1.30257 5.06603 1.47342 4.89517C1.56263 4.80597 1.68601 4.75961 1.81189 4.768L5.29047 4.99991L7.14662 3.14375L7.35546 1.26422C7.38214 1.02408 7.59845 0.851031 7.8386 0.877714C7.93734 0.888685 8.0294 0.932933 8.09964 1.00318L12.9995 5.90304C13.1704 6.0739 13.1704 6.35091 12.9995 6.52176C12.9293 6.59201 12.8372 6.63626 12.7385 6.64723L10.8589 6.85606L9.00278 8.71222L9.23468 12.1908C9.25076 12.4319 9.06835 12.6404 8.82726 12.6564C8.70138 12.6648 8.578 12.6185 8.48879 12.5293L6.21855 10.259L1.88752 12.7339L1.2688 12.1152Z"
                          fill="#BFC7D8" />
                      </svg>
                    </div>
                  </div>
                  <div class="alert-item-other">
                    <div class="item-name">
                      {{ listType !== 'notice' ? '项目：' : '' }}
                      {{ listType === 'notice' ? getFont(item.text) : (item.current_name || '-') }}
                    </div>
                    <div class="item-time">
                      {{ parseTime(item.created_at, '{m}-{d} {h}:{i}') }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </div>

      <ul ref="listRef" v-else-if="(dataBase?.length && topLength > 2) || !alertIsScroll" id="list" class="scroll-ul">
        <li v-for="(item, index) in dataBase" :key="index" @click="onShowDetail(item)"
          @mouseover="hoverItem(item.id, true)" @mouseout="outHoverItem()">
          <div class="alert-item-icon">
            <img class="item-icon" :src="getImg(item)">
          </div>
          <div class="alert-item-main">
            <div class="alert-item-title">
              <div v-if="listType === 'warn'" class="title">
                <marquee v-if="item.id === hoverId">{{ item.warn_content }}</marquee>
                <div v-else class="font-cont">
                  {{ item.warn_content }}
                </div>
              </div>
              <div v-else class="title">
                <div v-if="item.notice_type && item.announcement_source === 1" class="notice-item-type"
                  :class="getClass(item)">
                  [{{ item.notice_type }}]
                </div>
                <div v-if="item.notice_type && item.announcement_source === 2" class="notice-item-type"
                  :class="getClass(item)">
                  [系统]
                </div>
                <marquee v-if="item.id === hoverId">{{ item.title || item.warn_content }}</marquee>
                <div v-else class="font-cont">{{ item.title || item.warn_content }}</div>
                <svg class="to-top-bnt" v-if="item.is_to_top" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                  viewBox="0 0 14 14" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.2688 12.1152L3.74367 7.78414L1.47342 5.51389C1.30257 5.34304 1.30257 5.06603 1.47342 4.89517C1.56263 4.80597 1.68601 4.75961 1.81189 4.768L5.29047 4.99991L7.14662 3.14375L7.35546 1.26422C7.38214 1.02408 7.59845 0.851031 7.8386 0.877714C7.93734 0.888685 8.0294 0.932933 8.09964 1.00318L12.9995 5.90304C13.1704 6.0739 13.1704 6.35091 12.9995 6.52176C12.9293 6.59201 12.8372 6.63626 12.7385 6.64723L10.8589 6.85606L9.00278 8.71222L9.23468 12.1908C9.25076 12.4319 9.06835 12.6404 8.82726 12.6564C8.70138 12.6648 8.578 12.6185 8.48879 12.5293L6.21855 10.259L1.88752 12.7339L1.2688 12.1152Z"
                    fill="#BFC7D8" />
                </svg>
              </div>
            </div>
            <div class="alert-item-other">
              <div class="item-name">
                {{ listType !== 'notice' ? '项目：' : '' }}
                {{ listType === 'notice' ? getFont(item.text) : (item.current_name || '-') }}
              </div>
              <div class="item-time">
                {{ parseTime(item.created_at, '{m}-{d} {h}:{i}') }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, onMounted, watch, computed } from 'vue';
import { parseTime } from '@/utils/util';
import marquee from '../../marquee.vue';
const emits = defineEmits(["on-show-detail", "refresh"])

const props = defineProps({
  dataList: {
    type: Array,
    default: () => []
  },
  //warn:预警，notice公告
  listType: {
    type: String,
    default: 'warn'
  },
  warnTypes: {
    type: Array,
    default: () => []
  },

});

const listRef = ref(null);
const containerRef = ref(null);
const dataBase = ref([]);
const loading = ref(false);
const hoverId = ref(0);
const isScroll = ref(false);
const alertIsScroll = ref(true);
const page = ref(0);
const pageSize = ref(3);
const topLength = ref(0);

const getImg = computed(() => {
  return function (item) {
    //预警通知
    if (props.listType === 'warn') {
      const findData = props.warnTypes.find(v => item.warn_level?.includes(v.name));
      if (findData) {
        return findData.value;
      }
      return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/bec1e5b62656a63bf9789f9a9d856222.png';
    } else {
      //系统公告
      if (+item.announcement_source === 2) {
        return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/f9206d32bd8de908b6657c781674675b.png'
      } else {
        return 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/ee81493f96b17b6bf65ade245751315a.png'
      }
    }
  }
});

const getFont = computed(() => {
  return function (str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body.textContent || "-";
  }
});

const getClass = computed(() => {
  return function (item) {
    if (!["公告", "公报", "决议", "决定"].includes(item.notice_type)) {
      return "blue-card";
    } else {
      return "yellow-card";
    }
  }
});
//点击查看详情
const onShowDetail = (item) => {
  emits('on-show-detail', item, 'detail');
  console.log(item)
};

const hoverItem = (id, payload) => {
  if (id !== hoverId.value) {
    hoverId.value = id;
  }
  isScroll.value = payload;
};

const outHoverItem = () => {
  hoverId.value = 0;
  isScroll.value = false;
}

const getData=()=>{
  topLength.value=0
  dataBase.value = [...props.dataList];
  loading.value = false;
  dataBase.value.forEach(item => {
    if (item.is_to_top) {
      topLength.value++;
    }
  })
  if(topLength.value<=2 && topLength.value<dataBase.value.length){
    page.value = Math.ceil((dataBase.value.length-topLength.value) / (pageSize.value-topLength.value));
  }else{
    page.value = Math.ceil(dataBase.value.length / pageSize.value);
  }
}

onMounted(() => {
  getData();
})

watch(()=>props.dataList,()=>{
  getData();
})

const mouseIn =()=>{
  alertIsScroll.value=false;
}
const mouseOut=()=>{
  alertIsScroll.value=true;
}
</script>

<style lang="less" scoped>
.scrollUl {
  overflow-x: hidden;
  width: 100%;
  height: 198px;
  // overflow: hidden;
  position: relative;
}

.alertItem {
  width: 100%;
  height: 198px;
  overflow: hidden;
  position: relative;

  .scroll-ul {
    overflow-x: hidden;
    height: 100%;
  }

}

ul {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  /* 确保列表宽度充满容器 */
  li {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    gap: 4px;
    border-radius: var(--Radio8);
    justify-content: space-between;

    .unchoosed-icon {
      display: none;
      color: #BFC7D8;
    }

    .alert-item-icon {
      box-sizing: border-box;
      // width: 36px;
      // height: 36px;
      // padding: 2px;

      .item-icon {
        width: 36px;
        height: 36px;
      }
    }

    .alert-item-main {
      width: 320px;
      height: 40px;
      gap: 0px;

      .alert-item-other {
        display: flex;
        justify-content: space-between;
        padding-left: 8px;

        .item-name {
          font-family: MiSans VF;
          font-size: 12px;
          font-weight: 330;
          line-height: 18px;
          text-align: left;
          color: #707786;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
        }

        .item-time {
          font-family: MiSans VF;
          font-size: 12px;
          font-weight: 330;
          line-height: 18px;
          text-align: right;
          color: #A4ACBD;
          margin-left: 10px;
        }
      }

      .alert-item-title {
        height: 22px;
        gap: 8px;
        font-family: "MiSans VF";
        font-size: 14px;
        font-style: normal;
        font-weight: 330;
        line-height: 22px;
        color: #181B22;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;

        .title {
          width: 100%;
          height: 22px;
          padding-left: 8px;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;

          .to-top-bnt {
            position: absolute;
            right: 0px;
          }

          .notice-item-type {
            margin-right: 6px;
            font-family: PangMenZhengDao;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .blue-card {
            background: linear-gradient(180deg, #47D3FC 0%, #00ADFF 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 6px;
            font-family: PangMenZhengDao;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .yellow-card {
            background: linear-gradient(180deg, #FCC11E 0%, #FC9D0D 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 6px;
            font-family: PangMenZhengDao;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .font-cont {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .title-icon {
          display: flex;
          width: 22px;
          height: 22px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          img {
            width: 22px;
            height: 22px;
            flex-shrink: 0;
          }
        }

        .choosed-icon {
          color: var(--themeColor);
        }

      }
    }
  }

  li:hover {
    background: #F2F5FA;
    border-radius: 8px;

    .unchoosed-icon {
      display: block;
    }
  }
}
</style>