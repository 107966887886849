<template>
    <div v-loading="loading" class="alert-notifications">
        <!-- <alertDetailPop></alertDetailPop> -->
        <div class="alert-notice-title" @click="openAlertPop(mainItem, 'all')">
            <div class="title-left">
                <div class="title-left-icon">
                    <img class="left-icon"
                        src='https://openim-1309784708.cos.ap-shanghai.myqcloud.com/57023c72a7d53dc577272bcc65710389.png' />
                </div>
                <span class="title-left-letter">
                    预警通知
                </span>
            </div>
            <div class="title-right">
                <span class="title-right-letter" v-if="dataLength">
                    {{ dataLength }}{{ dataLength===99?"+":null }}
                </span>
                <div class="title-right-icon">
                    <img class="right-icon"
                        src='https://openim-1309784708.cos.ap-shanghai.myqcloud.com/10809eb5d0a4e685ce390b4f6d0cd796.png' />
                </div>
            </div>
        </div>
        <div class="alert-notice-list" v-if="dataLength">
            <alertItem 
                :dataList="dataList"
                :warnTypes="warnTypes"
                @on-show-detail="openAlertPop"
            />
        </div>
        <div class="noData" v-else>
            <div>
              <i class="iconfont iconzanwuyujing"></i>   
            </div> 
            <p>暂无预警</p>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import alertItem from './components/alertItem.vue';
import { dataInterface } from '@/apis/data';
import {  onMounted, ref ,defineProps,watch} from 'vue';

const dataLength = ref(0);
const dataList = ref([]);
const mainItem = ref([]);
const loading = ref(true);
const warnTypes = ref([]);

const props = defineProps({
    isUpdate:{
        type:Boolean,
        default:false
    }
})

const emits = defineEmits(["switchAlertPop"]);

const openAlertPop = (data, type) => {
    emits("switchAlertPop", data, type);
}

 //获取字典查询的预警通知图片
const getTypeImg = () => {
    dataInterface({
        object_uuid: "object617fbedcc1a6f",
        transcode: 0,
        view_uuid: "view66acb85cb0d9d",
        __method_name__: "dataList",
    }).then(res => {
      if (res.data.code === 200) {
        warnTypes.value = res.data.data;
      }
    })
  }

watch(()=>props.isUpdate,()=>{
    getAlertData();
})

onMounted(() => {
    getTypeImg();
    getAlertData();
})
const getAlertData=()=>{
    loading.value = true;
    let count =0;
    let list = [];
    dataInterface({},'api/graph/1288').then(res => {
        if (res.data.code === 200) {
            mainItem.value=res.data.data;
            res.data.data.forEach(item => {
                if(+item.is_read !== 1){
                    count++;
                    list.push(item);

                }
            });   
            dataLength.value=count;
            if(dataLength.value>99){
                dataLength.value=99;
            }
            dataList.value=list;
        }
        loading.value = false;
    })
}
</script>

<style lang="less" scoped>
.alert-notifications {
    display: flex;
    margin: 12px 0;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--Radio-12, 12px);
    background: var(--overall-surface-default, #FFF);
    position: relative;
    z-index: 1;
    .noData{
        width: 100%;
        height: 198px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #A4ACBD;
        i{
            text-align: center;
            font-family: "SF Pro";
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 48px; /* 184.615% */
        }
        p{
            font-family: "MiSans VF";
            font-size: 14px;
            font-style: normal;
            font-weight: 330;
            line-height: 22px; 
        }
    }
    .alert-notice-title {
        display: flex;
        justify-content: space-between;
        height: 56px;
        padding: 8px var(--spacing-10, 20px);
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-bottom: 1px solid var(--border-on-surface-weak, #F0F1F4);
        box-sizing: border-box;

        .title-right {
            display: flex;
            align-items: center;

            .title-right-letter {
                display: flex;
                height: 18px;
                min-width: 18px;
                padding: 0px 3px 0.5px 3px;
                justify-content: center;
                box-sizing: border-box;
                align-items: center;
                border-radius: var(--Radio-6, 6px);
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), var(--semantic-error-background-variant, #FC5558);
                color: var(--semantic-error-on-background-variant, #FFF4F4);
                // text-align: center;
                font-family: "MiSans VF";
                font-size: 12px;

                font-style: normal;
                font-weight: bold;
                line-height: 12px;
                /* 100% */
                box-shadow: 0px 0.5px 3px 0px rgba(252, 85, 88, 0.12), 0px 1px 2px 0px rgba(252, 85, 88, 0.12), 0px 0.5px 1px 0px rgba(252, 85, 88, 0.24);
            }
        }

        .title-right-icon {
            display: flex;
            width: var(--button-list-field-small, 24px);
            height: var(--button-list-field-small, 24px);
            justify-content: flex-end;
            align-items: center;
            border-radius: var(--Radio-6, 6px);

            .right-icon {
                width: var(--Radio-16, 16px);
                height: var(--Radio-16, 16px);
                // transform: rotate(-90deg);
                flex-shrink: 0;
            }
        }

        .title-left {
            display: flex;
            align-items: center;
            gap: 4px;
            .title-left-letter {
                color: var(--text-on-surface-primary, #181B22);
                font-family: "MiSans VF";
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: 24px;
                /* 150% */
                flex: 1 0 0;
            }

            .title-left-icon {

                //     display: flex;
                // width: 36px;
                // height: 36px;
                // justify-content: center;
                // align-items: center;
                .left-icon {
                    display: flex;
                    width: 36px;
                    height: 36px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                }
            }
        }
    }

    .alert-notice-list {
        // margin-right: 5px;
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
    }
}
</style>